import * as React from 'react';
import styled from 'styled-components';

const SNavItem = styled.li`

`;

interface IProps {
  children: React.ReactNode;
}

const NavItemMobil = ({ children }: IProps) => {

  return (
    <SNavItem>
      {children}
    </SNavItem>
  );
};

export default NavItemMobil;
