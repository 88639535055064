import * as React from 'react';
import styled from 'styled-components';
import { Input, Checkbox, Button } from '@components/inputs';

const Form = styled.form<React.FormHTMLAttributes<any>>`
  margin: 30px auto 0 auto;
  padding: 0 6px;
  
  @media (min-width: 576px) {
    padding: 0 40px;
  }
`;

const mapValidate: Partial<Record<keyof IValues, (value: any) => React.ReactNode | undefined>> = {
  name: (value) => (!value ? 'Пожалуйста, заполните это поле' : ''),
  phone: (value) => {
    if (!value) {
      return 'Пожалуйста, заполните это поле';
    }

    if (value.length < 18) {
      return 'Некорректный номер';
    }

    return '';
  },
  email: (value) => {
    if (!value) {
      return 'Пожалуйста, заполните это поле';
    }

    // eslint-disable-next-line max-len,no-useless-escape
    const regExp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!regExp.test(value)) {
      return 'Некорректный email';
    }

    return '';
  },
  confirmPolicy: (value) =>
    (!value ? 'Чтобы продолжить, необходимо подтвердить согласие на обработку персональных данных' : ''),
};

interface IValues {
  name?: string;
  phone?: string;
  email?: string;
  confirmPolicy?: boolean;
}

interface IProps {
  onSubmit(values?: IValues): void;
}

const Order = React.memo(({
  onSubmit: onSubmitProps,
}: IProps) => {
  const [values, setValues] = React.useState<IValues>({});
  const [errors, setErrors] = React.useState<Partial<Record<keyof IValues, React.ReactNode>>>({});
  const [isSubmitted, setSubmitted] = React.useState<boolean>(false);

  const onChange = (name) => ({ target: { value } }) => {
    setErrors({ ...errors, [name]: mapValidate[name](value) });
    setValues({ ...values, [name]: value });
  };

  const onChangeCheckbox = (name) => ({ target: { checked } }) => {
    setErrors({ ...errors, [name]: mapValidate[name](checked) });
    setValues({ ...values, [name]: checked });
  };

  const onSubmit = (event) => {
    event.preventDefault();
    setSubmitted(true);
    const newErrors = {};
    Object.entries(mapValidate).forEach(([name, validator]) => {
      const currentError = validator(values[name]);
      if (currentError) {
        newErrors[name] = currentError;
      }
    });
    setErrors(newErrors);
    if (!Object.keys(newErrors).length) {
      onSubmitProps(values);
    }
  };

  return (
    <Form onSubmit={onSubmit}>
      <Input
        error={errors.name}
        isSubmitted={isSubmitted}
        value={values.name}
        onChange={onChange('name')}
        label="Имя"
      />
      <Input
        error={errors.phone}
        isSubmitted={isSubmitted}
        mask="+7 (999) 999-99-99"
        value={values.phone}
        onChange={onChange('phone')}
        label="Номер телефона"
      />
      <Input
        error={errors.email}
        isSubmitted={isSubmitted}
        value={values.email}
        onChange={onChange('email')}
        label="email"
      />
      <Checkbox
        error={errors.confirmPolicy}
        value={values.confirmPolicy}
        onChange={onChangeCheckbox('confirmPolicy')}
        isSubmitted={isSubmitted}
      >
        <div>
          Принимаю условия обработки <br />
          <a target="_blank" href="/policy" title="Политика обработки персональных данных">
            персональных данных
          </a>
        </div>
      </Checkbox>
      <Button type="submit" width="100%">
        Отправить
      </Button>
    </Form>
  );
});

export default Order;
