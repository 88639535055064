import * as React from 'react';
import styled from 'styled-components';
import device from '@helpers/styled-breakpoints';

const Label = styled.label`
  display: block;
  position: relative;
  padding: 2px 2px 2px 40px;
  font-family: Geometria, sans-serif;
  font-size: 15px;
  cursor: pointer;
  
  a {
    color: #4D4DF3;
  }
`;

const Svg = styled.svg`
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
`;

interface IPropsRect {
  checked?: boolean;
}

const Rect = styled.rect<IPropsRect>`
  transition: all 200ms ease-out;
  transform-origin: center;
  transform: ${({ checked }) => (checked ? 'scale(1) rotateZ(0)' : 'scale(0) rotateZ(90deg)')};
`;

const Error = styled.div`
  font-family: Geometria, sans-serif;
  font-size: 12px;
  color: #e60003;
  margin-top: 10px;
`;

const Input = styled.input`
  position: absolute;
  height: 0;
  visibility: hidden;
`;

interface IProps {
  value?: boolean;
  children: React.ReactNode | Array<React.ReactNode>;
  isSubmitted: boolean;
  error?: React.ReactNode;

  onChange(e?: any): void;
}

const Checkbox = React.memo(({
  value = false,
  children,
  onChange,
  isSubmitted,
  error,
}: IProps) => {

  const isShowError = Boolean(isSubmitted && error);

  return (
    <div>
      <Label>
        <Svg width="26" height="26" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="2" y="2" height="36" width="36" fill="#8464fe" />
          <rect x="8" y="8" height="24" width="24" fill="#FFF" />
          <Rect checked={value} x="14" y="14" height="12" width="12" fill="#8464fe" />
        </Svg>
        <Input onChange={onChange} type="checkbox" checked={value} />
        {children}
      </Label>
      {isShowError ? <Error>{error}</Error> : null}
    </div>
  );
});

export default Checkbox;
