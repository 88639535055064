import * as React from "react";

function Whatsapp(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <path
        d="M256.1 0h-.1C114.8 0 0 114.8 0 256c0 56 18 107.9 48.7 150l-31.9 95.1 98.4-31.5C155.7 496.5 204 512 256.1 512 397.2 512 512 397.2 512 256S397.2 0 256.1 0z"
        fill="#25d165"
      />
      <path
        d="M405 361.5c-6.2 17.4-30.7 31.9-50.2 36.1-13.4 2.8-30.8 5.1-89.7-19.3-75.2-31.2-123.7-107.6-127.5-112.6-3.6-5-30.4-40.5-30.4-77.2s18.7-54.6 26.2-62.3c6.2-6.3 16.4-9.2 26.2-9.2 3.2 0 6 .2 8.6.3 7.5.3 11.3.8 16.3 12.6 6.2 14.9 21.2 51.6 23 55.4 1.8 3.8 3.6 8.9 1.1 13.9-2.4 5.1-4.5 7.4-8.3 11.7s-7.4 7.7-11.1 12.4c-3.5 4.1-7.4 8.4-3 15.9 4.4 7.4 19.4 31.9 41.5 51.6 28.6 25.4 51.7 33.6 60 37 6.2 2.6 13.5 2 18-2.8 5.7-6.2 12.8-16.4 20-26.5 5.1-7.2 11.6-8.1 18.4-5.6 6.9 2.4 43.5 20.5 51 24.2 7.5 3.8 12.5 5.6 14.3 8.7 1.8 3.4 1.8 18.2-4.4 35.7z"
        fill="#fafafa"
      />
    </svg>
  );
}

const MemoWhatsapp = React.memo(Whatsapp);
export default MemoWhatsapp;
