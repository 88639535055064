import * as React from 'react';
import styled from 'styled-components';
import {createCalcScale3d} from '@utils/math-addition';
import device from '@helpers/styled-breakpoints';
import Img from 'gatsby-image';
import useResponsive from '@hooks/responsive';
import ReactTilt from '../../../../modules/tilt';

const calcScale = createCalcScale3d(500, true);

const tiltOptions = {max: 7, perspective: 500, speed: 1000, scale: 1};

interface IStyledReactTilt {
  marginTop?: string;
}

const StyledReactTilt = styled(ReactTilt)<IStyledReactTilt>`
  position: relative;
  margin: ${({marginTop}) => marginTop || '150px'} auto 150px auto;
  width: 100%;
  
  @media ${device.mobil} {
    
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    width: 780px;
    height: 410px;
    transform-style: preserve-3d;
    transform: perspective(500px);
  }
  
  @media ${device.extra} {
  }
`;

const MobileWrapPortfolioItem = styled.div`
  position: relative;
  height: 330px;
  max-width: 780px;
  margin: 50px auto;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
    height: 410px;
  }
`;

interface IPropsTilt {
  children: React.ReactElement | Array<React.ReactNode>;
  marginTop?: string;
}

export const Tilt = React.memo(({
  children,
  marginTop,
}: IPropsTilt) => {
  const isDesktop = useResponsive('(min-width: 1024px)');

  if (isDesktop) {
    return (
      <StyledReactTilt
        options={tiltOptions}
        marginTop={marginTop}
        isDesktop={isDesktop}
      >
        {children}
      </StyledReactTilt>
    );
  }

  return (
    <MobileWrapPortfolioItem>
      {children}
    </MobileWrapPortfolioItem>
  );
});

export const PortfolioItemTitle = styled.h2`
  font-family: 'Geometria', sans-serif;
  text-transform: uppercase;
  font-weight: bold;
  color: white;
  margin: 25px 0 10px 30px;
  position: relative;
  z-index: 10;
  font-size: 20px;

  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    font-size: 34px;
    margin: 40px 0 10px 30px;
  }
  
  @media ${device.extra} {
  }
`;

export const BackgroundImgWrap = styled.div`
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  max-width: 781px;
  width: 100%;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    left: 0;
    transform: translateX(0);
  }
  
  @media ${device.extra} {
  }
`;

export const Background2ImgWrap = styled.div`
  position: absolute;
  left: 50%;
  top: 0;
  width: 100%;
  max-width: 781px;
  transform: translateX(-50%);
  display: none;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
    display: block;
  }
  
  @media ${device.desktop} {
    left: 0;
    transform: ${calcScale(100)} translateX(0);
  }
  
  @media ${device.extra} {
  }
`;

export const WrapContent = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  padding-top: 1px;
  padding-left: 15px;
  margin-left: -20px;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
    margin-left: 0;
  }
  
  @media ${device.desktop} {
    padding-left: 40px;
    transform: ${calcScale(200)};
  }
  
  @media ${device.extra} {
  }
`;

export const StyledImageBackground = styled(Img)`
  height: 280px;

  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
    height: 350px;
  }
  
  @media ${device.desktop} {
    height: auto;
  }
  
  @media ${device.extra} {
  }
`;
