import * as React from "react";

function BussinesCard(props) {
  return (
    <svg viewBox="0 0 70 70" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)" fill="#232323" fillOpacity={0.86}>
        <path d="M67.343 4.874H57.096V1.117C57.096.5 56.596 0 55.98 0H14.021c-.617 0-1.117.5-1.117 1.117v3.757H2.657A2.66 2.66 0 000 7.531v49.03a2.66 2.66 0 002.657 2.658h24.397v8.547H16.518a1.117 1.117 0 100 2.234h32.018a1.117 1.117 0 100-2.234h-5.59v-8.547h4.597a1.117 1.117 0 100-2.234H2.657a.429.429 0 01-.423-.423V7.532c0-.23.194-.424.423-.424h10.247v40.788H5.737a1.117 1.117 0 100 2.234h41.806a1.117 1.117 0 100-2.234h-8.77v-9.129c0-.617-.5-1.117-1.117-1.117H19.978c-.617 0-1.117.5-1.117 1.117v9.13h-3.723V5.992 2.234h39.724v10.178a1.117 1.117 0 102.234 0V7.108h10.247c.23 0 .423.194.423.424v49.03c0 .23-.194.423-.423.423h-2.928a1.117 1.117 0 100 2.234h2.928A2.66 2.66 0 0070 56.562V7.532a2.66 2.66 0 00-2.657-2.658zm-26.63 62.892H29.287v-8.547h11.424v8.547zM21.094 39.884H36.54v8.013H21.095v-8.013z" />
        <path d="M61.05 26.595c-.002-.016-.004-.033-.008-.049-.004-.023-.01-.046-.016-.069l-.01-.035a1.18 1.18 0 00-.023-.07l-.01-.026-.005-.014a.213.213 0 01-.003-.007l-3.96-9.861a1.117 1.117 0 00-2.072 0l-3.96 9.862-.008.02-.01.029c-.008.022-.017.044-.023.067l-.01.036c-.006.023-.012.045-.016.068l-.008.049-.008.058a1.14 1.14 0 00-.005.11v38.152A5.09 5.09 0 0055.979 70a5.09 5.09 0 005.085-5.085V26.762c0-.036-.002-.073-.005-.11l-.01-.057zm-7.921 1.284h5.7v31.9h-5.7v-31.9zm2.85-8l2.316 5.766h-4.631l2.315-5.767zm0 47.887a2.854 2.854 0 01-2.85-2.85v-2.903h5.7v2.902a2.854 2.854 0 01-2.85 2.851zM19.978 16.055h30.044c.617 0 1.117-.5 1.117-1.117V7.074c0-.617-.5-1.117-1.117-1.117H19.978c-.617 0-1.117.5-1.117 1.117v7.864c0 .617.5 1.117 1.117 1.117zm1.117-7.864h27.81v5.63h-27.81V8.19zM50.022 18.289H19.978a1.117 1.117 0 000 2.234h30.044a1.117 1.117 0 100-2.234zM37.656 24.246H19.978a1.117 1.117 0 100 2.234h17.678a1.117 1.117 0 100-2.234zM47.758 24.246h-5.634a1.117 1.117 0 100 2.234h5.634a1.117 1.117 0 100-2.234zM37.656 28.714H19.978a1.117 1.117 0 000 2.234h17.678a1.117 1.117 0 100-2.234zM47.543 28.714h-5.419a1.117 1.117 0 100 2.234h5.42a1.117 1.117 0 100-2.234zM37.656 33.182H19.978a1.117 1.117 0 000 2.234h17.678a1.117 1.117 0 100-2.234zM47.543 33.182h-5.419a1.117 1.117 0 100 2.234h5.42a1.117 1.117 0 100-2.234z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h70v70H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoBussinesCard = React.memo(BussinesCard);
export default MemoBussinesCard;
