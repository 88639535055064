import * as React from 'react';
import styled from 'styled-components';
import device from '@helpers/styled-breakpoints';

const Nav = styled.nav`
  display: none;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    display: block;
  }
  
  @media ${device.extra} {
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  transform: translateY(-100%);
  transition: 200ms ease-out transform;
  padding: 20px 0;
  background-color: #7F5BED;

  .isOpen & {
    transform: translateY(0);
  }
 
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    transform: translateY(0);
    flex-direction: row;
    padding: 0;
    background-color: transparent;
    transition: none;
 }
  
  @media ${device.extra} {
  }
`;

interface IProps {
  children: React.ReactNode;
}

const Navbar = ({
  children,
}: IProps) => {
  return (
    <Nav>
      <List>
        {children}
      </List>
    </Nav>
  );
};

export default Navbar;
