import * as React from "react";

function OnlineStore(props) {
  return (
    <svg viewBox="0 0 71 71" fill="none" {...props}>
      <path
        d="M65.675 71H5.325A5.332 5.332 0 010 65.675V5.325A5.332 5.332 0 015.325 0h60.35A5.332 5.332 0 0171 5.325v60.35A5.332 5.332 0 0165.675 71zM5.325 2.367a2.962 2.962 0 00-2.958 2.958v60.35a2.962 2.962 0 002.958 2.958h60.35a2.96 2.96 0 002.958-2.958V5.325a2.962 2.962 0 00-2.958-2.958H5.325z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M10.65 11.833H5.917a1.183 1.183 0 01-1.184-1.183V5.917c0-.655.53-1.184 1.184-1.184h4.733c.653 0 1.183.53 1.183 1.184v4.733c0 .654-.53 1.183-1.183 1.183zM7.1 9.467h2.367V7.1H7.1v2.367zM20.117 11.833h-4.734A1.183 1.183 0 0114.2 10.65V5.917c0-.655.53-1.184 1.183-1.184h4.734c.653 0 1.183.53 1.183 1.184v4.733c0 .654-.53 1.183-1.183 1.183zm-3.55-2.366h2.366V7.1h-2.366v2.367zM29.583 11.833H24.85a1.183 1.183 0 01-1.183-1.183V5.917c0-.655.53-1.184 1.183-1.184h4.733c.654 0 1.184.53 1.184 1.184v4.733c0 .654-.53 1.183-1.184 1.183zm-3.55-2.366H28.4V7.1h-2.367v2.367z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M69.817 16.567H1.183A1.183 1.183 0 010 15.383V5.325A5.332 5.332 0 015.325 0h60.35A5.332 5.332 0 0171 5.325v10.058c0 .655-.53 1.184-1.183 1.184zM2.367 14.2h66.266V5.325a2.962 2.962 0 00-2.958-2.958H5.325a2.962 2.962 0 00-2.958 2.958V14.2zM33.133 40.233H7.1a1.183 1.183 0 01-1.183-1.183V22.483c0-.654.53-1.183 1.183-1.183h26.033c.654 0 1.184.529 1.184 1.183V39.05c0 .654-.53 1.183-1.184 1.183zm-24.85-2.366H31.95v-14.2H8.283v14.2zM57.983 29.583H39.05a1.183 1.183 0 110-2.366h18.933a1.183 1.183 0 110 2.366zM63.9 24.85H39.05a1.183 1.183 0 110-2.367H63.9a1.183 1.183 0 110 2.367zM50.883 40.233H39.05a1.183 1.183 0 01-1.183-1.183v-4.733c0-.655.53-1.184 1.183-1.184h11.833c.653 0 1.184.53 1.184 1.184v4.733c0 .654-.53 1.183-1.184 1.183zm-10.65-2.366H49.7V35.5h-9.467v2.367z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M11.833 35.5a1.182 1.182 0 01-.837-2.02l4.734-4.733a1.182 1.182 0 111.673 1.673l-4.733 4.733a1.18 1.18 0 01-.837.347z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M21.3 35.5a1.18 1.18 0 01-.837-.347L15.73 30.42a1.182 1.182 0 111.673-1.673l4.734 4.733a1.182 1.182 0 01-.837 2.02z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M18.933 33.133a1.182 1.182 0 01-.837-2.02l4.734-4.733a1.182 1.182 0 111.673 1.673l-4.733 4.734a1.18 1.18 0 01-.837.346z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M28.4 33.133a1.18 1.18 0 01-.837-.346l-4.733-4.734a1.182 1.182 0 111.673-1.673l4.734 4.733a1.182 1.182 0 01-.837 2.02zM33.133 63.9H7.1a1.183 1.183 0 01-1.183-1.183V46.15c0-.654.53-1.183 1.183-1.183h26.033c.654 0 1.184.529 1.184 1.183v16.567c0 .654-.53 1.183-1.184 1.183zm-24.85-2.367H31.95v-14.2H8.283v14.2zM57.983 53.25H39.05a1.183 1.183 0 110-2.367h18.933a1.183 1.183 0 110 2.367zM63.9 48.517H39.05a1.183 1.183 0 110-2.367H63.9a1.183 1.183 0 110 2.367zM50.883 63.9H39.05a1.183 1.183 0 01-1.183-1.183v-4.734c0-.654.53-1.183 1.183-1.183h11.833c.653 0 1.184.529 1.184 1.183v4.734c0 .654-.53 1.183-1.184 1.183zm-10.65-2.367H49.7v-2.366h-9.467v2.366z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M11.833 59.167a1.182 1.182 0 01-.837-2.02l4.734-4.734a1.182 1.182 0 111.673 1.674L12.67 58.82a1.18 1.18 0 01-.837.347z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M21.3 59.167a1.18 1.18 0 01-.837-.347l-4.733-4.733a1.182 1.182 0 111.673-1.673l4.734 4.733a1.182 1.182 0 01-.837 2.02z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M18.933 56.8a1.182 1.182 0 01-.837-2.02l4.734-4.733a1.182 1.182 0 111.673 1.673l-4.733 4.733a1.18 1.18 0 01-.837.347z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M28.4 56.8a1.18 1.18 0 01-.837-.347L22.83 51.72a1.182 1.182 0 111.673-1.673l4.734 4.733a1.182 1.182 0 01-.837 2.02z"
        fill="#232323"
        fillOpacity={0.86}
      />
    </svg>
  );
}

const MemoOnlineStore = React.memo(OnlineStore);
export default MemoOnlineStore;
