/* eslint-disable max-len */
import styled from 'styled-components';
import * as React from 'react';

export const MoreInfo = styled.div`
  font-family: Geometria, sans-serif;
  display: inline-flex;
  position: relative;
  padding-right: 20px;
  margin-top: 12px;
  align-items: center;
    
  svg {
    position: absolute;
    top: 6px;
    right: 0;
  }
`;

export const IconArrow = () => (
  <svg xmlns="http://www.w3.org/2000/svg" width="15" height="9" viewBox="0 0 15 9" fill="none">
    <path
      d="M0.585938 5.08594L12.9949 5.08594L10.9393 7.13156C10.7099 7.35985 10.709 7.73083 10.9373 7.9602C11.1656 8.18959 11.5366 8.19044 11.766 7.96219L14.8276 4.91532C14.8278 4.91514 14.828 4.91493 14.8281 4.91476C15.0569 4.68648 15.0577 4.31429 14.8282 4.08525C14.828 4.08507 14.8279 4.08487 14.8277 4.08469L11.766 1.03782C11.5367 0.809594 11.1657 0.810384 10.9374 1.03981C10.7091 1.26917 10.71 1.64016 10.9393 1.86844L12.9949 3.91407L0.585938 3.91406C0.262325 3.91406 3.45881e-07 4.17639 3.1759e-07 4.5C2.89299e-07 4.82361 0.262325 5.08594 0.585938 5.08594Z"
      fill="#232323"
      fillOpacity="0.5"
    />
  </svg>
);

const ButtonBackStyled = styled.button<any>`
  border: none;
  background: transparent;
  cursor: pointer;
  padding: 4px
`;

interface IButtonBack {
  onClick(e: any): void;
}

export const ButtonBack = ({ onClick }: IButtonBack) => (
  <ButtonBackStyled onClick={onClick}>
    <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
      <circle cx="24" cy="24" r="23" stroke="#4A599C" strokeWidth="2" />
      <path
        d="M20.0735 24.0042L27.5555 16.522C27.7615 16.3164 27.8748 16.0416 27.8748 15.7486C27.8748 15.4554 27.7615 15.1808 27.5555 14.975L26.8999 14.3197C26.6942 14.1135 26.4192 14 26.1262 14C25.8332 14 25.5586 14.1135 25.3527 14.3197L16.4443 23.228C16.2376 23.4345 16.1244 23.7104 16.1252 24.0037C16.1244 24.2984 16.2374 24.574 16.4443 24.7807L25.3444 33.6803C25.5503 33.8865 25.8249 34 26.1181 34C26.4111 34 26.6857 33.8865 26.8918 33.6803L27.5472 33.025C27.9737 32.5985 27.9737 31.9042 27.5472 31.4779L20.0735 24.0042Z"
        fill="#4A599C"
      />
    </svg>
  </ButtonBackStyled>
);
