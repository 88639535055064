import * as React from 'react';
import styled, { css } from 'styled-components';
import Lilubags from '@components/blocks/portfolio-items/lilubags';
import Brior from '@components/blocks/portfolio-items/brior';
import SmmLike from '@components/blocks/portfolio-items/smm-like';
import { useCallback, useRef } from 'react';

const Wrap = styled.div<{isDesktop: boolean, enableAnimation: boolean}>`
  width: 100vw;
  background-color: white;
  background-position: 0 0;
  position: relative;
  height: 100vh;
  overflow-y: scroll;
  
  ${(props) => (!props.isDesktop || !props.enableAnimation) && css`
     overflow: visible;
     height: auto;
     min-height: 100vh;
  `}
`;

const Title = styled.h2`
  letter-spacing: 2px;
  font-family: 'Geometria', sans-serif;
  font-weight: 700;
  text-align: center;
  padding: 40px 0 0 0;
  font-size: 30px;
  
  @media (min-width: 576px) {
    font-size: 36px;
  }
  
  @media (min-width: 1024px) {
    font-size: 40px;
  }
`;

interface IProps {
  enableAnimation: boolean;
  isDesktop: boolean;
  downHandler: (e: Event) => void;
  upHandler: (e: Event) => void;
}

const Portfolio = ({
  enableAnimation,
  isDesktop,
  downHandler,
  upHandler,
}: IProps) => {
  const scrollRef = useRef(null);
  const scrollHandler = useCallback((e) => {
    e.stopPropagation();
    const isDownDirection = e.deltaY > 0;
    const scrollDifference = scrollRef.current.scrollHeight - scrollRef.current.scrollTop;
    const scrollSizeDifference = Math.floor(scrollDifference - scrollRef.current.clientHeight);
    if (isDownDirection && scrollSizeDifference === 0) {
      downHandler(e);
    } else if (!isDownDirection && upHandler && scrollRef.current.scrollTop === 0) {
      upHandler(e);
    }
  }, []);

  return (
    <Wrap isDesktop={isDesktop} enableAnimation={enableAnimation} ref={scrollRef} onWheel={enableAnimation ? scrollHandler : () => null}>
      <Title>Наши работы</Title>

      <Lilubags />
      <Brior />
      <SmmLike />
    </Wrap>
  );

};

export default Portfolio;
