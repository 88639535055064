import * as React from 'react';
import styled, {css} from 'styled-components';
import device from '@helpers/styled-breakpoints';
import Wave from '@components/elements/wave';
import Letters, {IRenderPropsArgs as ILettersRenderPropsArgs} from '@components/elements/letters';
import {getPercentOfNumber} from '@utils/math-addition';
import IntroIllustration from '@components/elements/into-illustration';
import HeaderAnimationBtn from '@components/blocks/header-animation-btn';

type AnimationStates = 'default' | 'entered' | 'scrolled';

const Wrap = styled.div`
  background-image: linear-gradient(to top, #1F1564 10%, #541C64 90%);
  width: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    height: 100vh;
    min-height: 100vh;
    max-height: 100vh;
  }
  
  @media ${device.extra} {
  }
`;

const InnerWrap = styled.div`
  position: relative;
  z-index: 10;
  padding: 125px 15px 0 15px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  
  @media ${device.mobil} {
    padding: 125px 15px 0 15px;
  }
  
  @media ${device.tablet} {
    padding: 175px 60px 0 60px;
  }
  
  @media ${device.desktop} {
    width: 980px;
    flex-direction: row;
    padding: 190px 0 0 0;  
  }
  
  @media ${device.extra} {
    width: 1020px;
    padding: 190px 0 0 0;
  }
`;

interface IWrapText {
  textAnimationProgress: number;
  animationState: AnimationStates;
  isDesktop: boolean;
  enableAnimation: boolean;
}

const WrapText = styled.div.attrs<IWrapText>(({
  textAnimationProgress,
  animationState,
  isDesktop,
  enableAnimation,
}) => ({
  style: (animationState === 'scrolled') && (!enableAnimation) ? {
    opacity: isDesktop ? 1 - textAnimationProgress : 1,
    transform: isDesktop
      ? `translateX(-${getPercentOfNumber(textAnimationProgress * 100, 100)}px)` : 'none',
    transitionDuration: '400ms',
    transitionTimingFunction: 'ease-out',
  } : {},
}))<IWrapText>`
  transform: translateX(0) !important;
`;

const H1 = styled.h1`
  font-family: 'Geometria', sans-serif;
  font-weight: 700;
  color: #fff;
  letter-spacing: 2px;
  text-align: center;
  font-size: 21px;
  margin-bottom: 10px;
  margin-top: 10px;

  @media ${device.mobil} {
    font-size: 30px;
  }
  
  @media ${device.tablet} {
    font-size: 32px;
  }
  
  @media ${device.desktop} {
    text-align: left;
    font-size: 35px;
  }
  
  @media ${device.extra} {
  }
`;

const WrapContent = styled.div`
  flex: 1 0 50%;
`;

const textStates = {
  default: css`
    transform: translateX(-100px);
    opacity: 0;
`,
  entered: css`
    transform: translateY(0);
    opacity: 1;    
`,
};

interface IText {
  animationState: AnimationStates;
  isDesktop: boolean;
  enableAnimation: boolean;
}

const Text = styled.div<IText>`
  opacity: 0.9;
  line-height: 1.5;
  font-size: 15px;
  font-weight: 400;
  margin-top: 25px;
  margin-bottom: 35px;
  color: white;
  font-family: 'Roboto', sans-serif;
  transition: ${({enableAnimation}) => enableAnimation && '500ms ease-out transform, 500ms ease-out opacity'};
  ${({
    animationState, isDesktop, enableAnimation,
  }) => isDesktop && enableAnimation ? textStates[animationState] : textStates.entered}
  
  span {
    color: #9dafff;
  }
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    display: block;
    max-width: 74%;
  }
  
  @media ${device.extra} {
  }
`;

const Waves = styled.div`
  position: absolute;
  left: -1000px;
  right: 0;
  top: -600px;
  transform: rotateZ(-20deg);
  z-index: 5;
`;

const spanStates = {
  default: css`
    transform: translateY(-15px);
    opacity: 0;
`,
  entered: css`
    transform: translateY(0);
    opacity: 1;    
`,
};

const IntroUl = styled.ul`
  padding-left: 8px;
  margin: 8px;
`;

type AnimationStatesSpan = 'default' | 'entered';

interface ISpan {
  animationState: AnimationStatesSpan;
  index: number;
  enableAnimation: boolean;
}

const Span = styled.span<ISpan>`
  --char-index:${({index}) => index};
  transition: ${({enableAnimation}) => enableAnimation && '200ms ease-out transform, 200ms ease-out opacity'};
  transition-delay: calc(var(--char-index) * 35ms);
  display: inline-flex;
  ${({animationState, enableAnimation}) => enableAnimation ? spanStates[animationState] : spanStates.entered}
`;

interface IProps {
  scrollPercent: number;
  textAnimationProgress: number;
  isDesktop: boolean;
  enableAnimation: boolean;
  setEnableAnimation: (value: boolean) => void;
  isEnougthHeight: boolean;
}

const {useEffect, useState} = React;

/* eslint-disable react/jsx-one-expression-per-line */
const Intro = React.memo(({
  scrollPercent,
  textAnimationProgress,
  isDesktop,
  enableAnimation,
  isEnougthHeight,
  setEnableAnimation,
}: IProps) => {
  const [animationStateSpan, setAnimationStateSpan] = useState<AnimationStatesSpan>('default');
  const [animationState2, setAnimationState2] = useState<AnimationStates>('default');

  useEffect(() => {
    const timerId1 = setTimeout(() => {
      setAnimationStateSpan('entered');
    }, 500);

    const timerId2 = setTimeout(() => {
      setAnimationState2('entered');
    }, 1500);

    return () => {
      clearTimeout(timerId1);
      clearTimeout(timerId2);
    };
  }, []);

  useEffect(() => {
    if (animationState2 !== 'default') {
      setAnimationState2('scrolled');
    }
  }, [scrollPercent]);

  const h1Content = <>Веб-сайты&nbsp;для<br />современного&nbsp;бизнеса</>;

  return (
    <Wrap>
      <Waves>
        <Wave
          paused={(scrollPercent > 0.1) || !enableAnimation || !isDesktop}
          fill="#000"
          opacity={0.25}
          cssTop="-10px"
          height={100}
          amplitude={60}
          points={7}
          speed={0.15}
        />
        <Wave
          paused={(scrollPercent > 0.1) || !enableAnimation || !isDesktop}
          fill="#000"
          opacity={0.25}
          cssTop="-400px"
          height={200}
          amplitude={60}
          points={7}
          speed={0.15}
        />
      </Waves>

      <InnerWrap>
        <WrapContent>
          <WrapText
            animationState={animationState2}
            textAnimationProgress={textAnimationProgress}
            isDesktop={isDesktop}
            enableAnimation={enableAnimation}
          >
            <H1>
              {enableAnimation && isDesktop ? (
                <Letters content={h1Content}>
                  {({
                    value,
                    key,
                    index,
                  }: ILettersRenderPropsArgs) => (
                    <Span
                      key={key}
                      index={index}
                      animationState={animationStateSpan}
                      enableAnimation={enableAnimation}
                    >
                      {value}
                    </Span>
                  )}
                </Letters>
              ) : h1Content}
            </H1>
            <Text
              animationState={animationState2}
              isDesktop={isDesktop}
              enableAnimation={enableAnimation}
            >
              Более трех лет мы успешно разрабатываем <span>сайты любой сложности</span>.
              Нам можно доверить любую задачу в сфере современной WEB-индустрии.
              Поможем определить стек технологий и найти наиболее оптимальный путь к
              разработке <span>вашей идеи</span>. С чем мы работаем:
              <IntroUl>
                <li>
                  сайты-визитки
                </li>
                <li>
                  лендинги
                </li>
                <li>
                  корпоративные сайты
                </li>
                <li>
                  интернет магазины
                </li>
                <li>
                  личные кабинеты
                </li>
                <li>
                  онлайн сервисы
                </li>
                <li>
                  мобильные приложения
                </li>
                <li>
                  решаем различные задачи в сфере автоматизации бизнеса
                </li>
              </IntroUl>
            </Text>
          </WrapText>
        </WrapContent>
        <IntroIllustration enableAnimation={enableAnimation} isDesktop={isDesktop} />
      </InnerWrap>
      <HeaderAnimationBtn
        isDesktop={isDesktop}
        enableAnimation={enableAnimation}
        setEnableAnimation={setEnableAnimation}
        isEnougthHeight={isEnougthHeight}
      />
    </Wrap>
  );
});

export default Intro;
