import * as React from 'react';
import styled, {css} from 'styled-components';

const mapButtonThemes = {
  primary: css`
    color: #fff;
    background: #4a599c;
    border: 1px solid #4a599c;
    
    &:hover {
      border-color: #2c3971;
      background: #2c3971;
    }
  `,
  secondary: css`
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
    
    &:hover {
      color: #4a599c;
      background: #fff;
    }
  `,
  tertiary: css`
    color: #4a599c;
    background: transparent;
    border: 1px solid #4a599c;
    
    &:hover {
      color: #fff;
      background: #2c3971;
    }
  `,
  quaternary: css`
    color: #fff;
    border: 1px solid #fff;
    background: transparent;
    border-radius: 5px;
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 18px;
    display: flex;
    align-items: center;
    padding: 16px 0 14px 0;
    width: 230px;
    
    svg {
      margin-right: 13px;
    }
    
    &:hover {
      color: #4a599c;
      background: #fff;
      svg {
        stroke: #4a599c;
        path:nth-child(2) {
          fill: #4a599c;
        }
      }
    }
  `,
};

type ButtonTheme = keyof typeof mapButtonThemes;

interface IPropsButton {
  theme: ButtonTheme;
  margin: string;
  width: string;
}

const baseButtonCss = css`
  cursor: pointer;
  font-family: 'Geometria', sans-serif;
  font-weight: 500;
  text-decoration: none;
  padding: 14px 36px;
  border-radius: 50px;
  outline: none;
  transition-property: border, background;
  transition-timing-function: ease;
  transition-duration: .2s;
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ButtonStyled = styled.button<IPropsButton>`
  margin: ${({margin}) => margin};
  ${baseButtonCss}
  ${({theme}) => mapButtonThemes[theme]}
  width: ${({width}) => width};
`;

const Link = styled.a<IPropsButton>`
  margin: ${({margin}) => margin};
  ${baseButtonCss}
  ${({theme}) => mapButtonThemes[theme]}
  width: ${({width}) => width};
`;

interface IProps extends React.ButtonHTMLAttributes<any> {
  children: React.ReactNode;
  theme?: ButtonTheme;
  margin?: string;
  href?: string;
  width?: string;
}

const Button = React.memo(({
  children,
  theme = 'primary',
  margin = '30px auto 0 auto',
  href,
  width,
  ...restProps
}: IProps) => {

  if (href) {
    return (
      <Link
        theme={theme}
        margin={margin}
        href={href}
        width={width}
        {...restProps}
      >
        {children}
      </Link>
    );
  }

  return (
    <ButtonStyled
      theme={theme}
      margin={margin}
      width={width}
      {...restProps}
    >
      {children}
    </ButtonStyled>
  );

});

export default Button;
