import * as React from 'react';

interface IProps {
}

const AnimateOff = React.memo(({}: IProps) => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 21 20" fill="none" stroke="#fff">
      <path
        d="M16.473 16.364L3.745 3.636m12.728 12.728A9 9 0 103.745 3.636l12.728 12.728zm0 0A9 9 0 013.745 3.636l12.728 12.728z"
        strokeWidth="2"
      />
    </svg>
  );
});

export default AnimateOff;
