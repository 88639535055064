import * as React from 'react';
import styled from 'styled-components';
import {LogoMiniBright} from '@components/elements/icons/webstap-logo';
import {NavbarMobil} from '@components/blocks/navbar-mobil';
import {Link as GatsbyLink} from 'gatsby';
import NavbarItemsWebstap from '@components/blocks/navbar-items-webstap';

const SHeader = styled.div`
  display: flex;
  width: 100%;
  margin: 0 auto;
  padding: 20px 15px 0 15px;
  position: absolute;
  z-index: 9999;
  
  @media (min-width: 768px) {
    padding: 40px 0 0 0;
  }
  
  @media (min-width: 1024px) {
    height: 200px;
  }
`;

const LogoLink = styled(GatsbyLink)`
  width: 200px;
  
  @media (min-width: 576px) {
    width: 350px;
  }
  
  @media (min-width: 768px) {
    margin-left: 60px;
  }
  
  @media (min-width: 1024px) {
    width: 400px;
    margin-left: 7px;
  }
`;

const InnerWrap = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  
  @media (min-width: 1024px) {
    width: 1024px;
    margin: 0 auto;
  }
`;

interface IProps {
  refIntro: HTMLDivElement;
  refPortfolio: HTMLDivElement;
  refRate: HTMLDivElement;
}

const Header = ({
  refIntro,
  refPortfolio,
  refRate,
}: IProps) => {
  return (
    <SHeader>
      <InnerWrap>
        <LogoLink to="/">
          <LogoMiniBright />
        </LogoLink>

        <NavbarMobil>
          <NavbarItemsWebstap
            refIntro={refIntro}
            refPortfolio={refPortfolio}
            refRate={refRate}
          />
        </NavbarMobil>
      </InnerWrap>

    </SHeader>
  );
};

export default Header;
