/* eslint-disable max-len */
import * as React from 'react';
import styled from 'styled-components';
import {
  MoreInfo,
  IconArrow,
} from '@components/elements/order-elements';

const Wrap = styled.div`
  background-color: white;
  border-radius: 30px;
  padding: 22px 19px;
  cursor: pointer;
  color: #521C64;
  box-shadow: 5px 5px 14px rgba(0, 0, 0, 0.16);
  transition: box-shadow 200ms ease-out, transform 150ms ease-out;

  &:hover {
    box-shadow: 5px 5px 20px rgba(0,0,0,0.51);
    transform: scale(1.025);    
  }
`;

const Title = styled.div`
  font-family: Geometria, sans-serif;
  font-size: 20px;
  margin: 20px 0 8px 0;
  
  @media (min-width: 576px) {
  }
  
  @media (min-width: 768px) {
    font-size: 16px;
  }
  
  @media (min-width: 1308px) {
    font-size: 20px;
  }
  
  @media (min-width: 1860px) {
  }
`;

const Cost = styled.div`
  font-family: Geometria, sans-serif;
  font-weight: bold;
    font-size: 26px;
  
  span {
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: bold;
    margin-right: 10px;
    font-size: 16px;
  }
  
  @media (min-width: 1308px) {
    font-size: 32px;
    
    span {
      font-size: 18px;
    }
  }
`;

const WrapIcon = styled.div`
  width: 80px;
  height: 80px;
`;

interface IPropsRateElement {
  title: React.ReactNode;
  children: React.ReactNode;
  cost: string;

  onClick(e: any): void;
}

const RateElement = ({
  title,
  cost,
  children,
  onClick,
}: IPropsRateElement) => {
  return (
    <Wrap onClick={onClick}>
      <WrapIcon>
        {children}
      </WrapIcon>
      <Title>
        {title}
      </Title>
      <Cost>
        <span>от</span>
        {cost}₽
      </Cost>
      <MoreInfo>
        <span>подробнее</span>
        <IconArrow />
      </MoreInfo>
    </Wrap>
  );
};

export default RateElement;
