/* eslint-disable max-len */
import * as React from 'react';
import styled from 'styled-components';
import Modal from 'react-responsive-modal';
import debounce from 'lodash.debounce';
import { getScrollbarWidth } from '@utils/dom-utils';

const Button = styled.button`
  display: flex;
  height: 40px;
  width: 40px;
  border: none;
  background-color: transparent;
  cursor: pointer;
  position: absolute;
  top: 4px;
  right: 4px;
  outline: none;
  
  path {
    fill: black;
  }
    
  @media (min-width: 768px) {
    top: 0;
    right: -50px;

    path {
      fill: white;
    }   
  }
`;

interface IPropsCloseButton {
  onClick(e?: any): void;
}

const CloseButton = ({onClick}: IPropsCloseButton) => (
  <Button onClick={onClick}>
    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 40 40" fill="none">
      <path
        d="M24.4055 20.0007L39.361 5.04456C39.7724 4.63285 39.9993 4.08358 40 3.49789C40 2.91188 39.773 2.36196 39.361 1.9509L38.0504 0.640656C37.6384 0.227973 37.0891 0.00195658 36.5028 0.00195658C35.9174 0.00195658 35.3681 0.227973 34.9561 0.640656L20.0006 15.5958L5.04455 0.640656C4.63317 0.227973 4.08358 0.00195658 3.49756 0.00195658C2.91219 0.00195658 2.3626 0.227973 1.95122 0.640656L0.64 1.9509C-0.213333 2.80423 -0.213333 4.1922 0.64 5.04456L15.5958 20.0007L0.64 34.9561C0.228293 35.3685 0.00162602 35.9177 0.00162602 36.5034C0.00162602 37.0891 0.228293 37.6384 0.64 38.0504L1.95089 39.3607C2.36228 39.773 2.91219 39.9994 3.49724 39.9994C4.08325 39.9994 4.63285 39.773 5.04423 39.3607L20.0003 24.4052L34.9558 39.3607C35.3678 39.773 35.9171 39.9994 36.5024 39.9994H36.5031C37.0888 39.9994 37.638 39.773 38.0501 39.3607L39.3606 38.0504C39.772 37.6387 39.999 37.0891 39.999 36.5034C39.999 35.9177 39.772 35.3685 39.3606 34.9564L24.4055 20.0007Z"
        fill="white"
      />
    </svg>
  </Button>
);

const Content = styled.div`
  background-color: white;
  border-radius: 6px;
  box-shadow: 0 2px 24px 2px rgba(0, 0, 0, 0.4);
  padding: 40px 20px;  
  
  @media (min-width: 576px) {
    padding: 40px;  
    max-width: 460px;
  }
`;

const styleBodyWithModalOpen = {
  overflowY: 'hidden',
  paddingRight: '0',
};

const styleBodyWithModalClose = {
  overflowY: 'auto',
  paddingRight: '0',
};

const fixBodyAfterClose = debounce((callback) => {
  Object.entries(styleBodyWithModalClose).forEach(([key, value]) => {
    document.body.style[key] = value;
  });
  if (callback) {
    callback();
  }
}, 250);

const styles = {
  modal: {
    padding: 0,
    backgroundColor: 'transparent',
  },
};

interface IProps {
  children: React.ReactNode;
  isOpen?: boolean;

  onClose(): void;

  callbackAfterClose?(): void;
  enableAnimation: boolean;
}

const BaseModal = React.memo(({
  children,
  isOpen,
  onClose,
  callbackAfterClose,
  enableAnimation,
}: IProps) => {

  React.useEffect(() => {
    if (isOpen) {
      fixBodyAfterClose.cancel();
      Object.entries(styleBodyWithModalOpen).forEach(([key, value]) => {
        if (key === 'paddingRight' && !enableAnimation) {
          document.body.style[key] = `${getScrollbarWidth()}px`;
        } else {
          document.body.style[key] = value;
        }
      });
    } else {
      fixBodyAfterClose(callbackAfterClose);
    }
  }, [isOpen]);

  const scrollHandler = React.useCallback((e) => {
    e.stopPropagation();
  }, []);

  return (
    <div onWheel={enableAnimation ? scrollHandler : (e) => e.preventDefault()}>
      <Modal
        open={isOpen}
        onClose={() => onClose()}
        center
        blockScroll={false}
        showCloseIcon={false}
        styles={styles}
      >
        <CloseButton onClick={() => onClose()} />
        <Content>
          {children}
        </Content>
      </Modal>
    </div>
  );
});

export default BaseModal;
