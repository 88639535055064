import * as React from "react";

function Telegram(props) {
  return (
    <svg viewBox="0 0 512 512" {...props}>
      <circle cx={256} cy={256} r={256} fill="#30a7db" />
      <path
        d="M214 293.9l101 76.2c6.9 5.2 16.9 1.6 18.9-6.8l49-209.1c2.2-9.4-7.1-17.4-16.1-13.9l-246.3 96.9c-10.4 4.1-10 19 .6 22.6l59.3 19.7 48.2-32.3c12.2-8.2 25.6 8.5 15 18.7l-29.6 28z"
        fill="#fff"
      />
      <path fill="#d2d2d7" d="M180.4 279.4l24.9 89.5 8.7-75 132.2-125.7z" />
      <path fill="#b9b9be" d="M251.6 322.3l-46.3 46.6 8.7-75z" />
    </svg>
  );
}

const MemoTelegram = React.memo(Telegram);
export default MemoTelegram;
