import * as React from "react";

function Other(props) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="79"
      height="79"
      fill="none"
      viewBox="0 0 79 79"
      {...props}
    >
      <g fill="#232323" fillOpacity="0.86" clipPath="url(#clip0)">
        <path d="M67.208 3.958H3.876A3.88 3.88 0 000 7.834v55.415a3.88 3.88 0 003.876 3.876h42.221a1.237 1.237 0 100-2.474H3.876a1.404 1.404 0 01-1.402-1.402V16.987h48.9a1.237 1.237 0 100-2.473h-48.9v-6.68c0-.773.629-1.402 1.402-1.402h63.332c.773 0 1.402.629 1.402 1.402v6.68h-4.041a1.237 1.237 0 100 2.474h4.04v25.15a1.237 1.237 0 102.475 0V7.835a3.88 3.88 0 00-3.876-3.876z"></path>
        <path d="M59.292 14.514h-2.64a1.237 1.237 0 100 2.474h2.64a1.237 1.237 0 100-2.474zM6.528 9.236h-.013c-.684 0-1.23.554-1.23 1.237s.56 1.237 1.243 1.237a1.237 1.237 0 000-2.474zM17.083 9.236h-.013c-.683 0-1.23.554-1.23 1.237s.56 1.237 1.243 1.237a1.237 1.237 0 000-2.474zM11.792 9.236h-.013c-.683 0-1.23.554-1.23 1.237s.56 1.237 1.243 1.237a1.237 1.237 0 000-2.474zM64.569 9.236H22.348a1.237 1.237 0 100 2.474h42.22a1.237 1.237 0 100-2.474zM64.569 46.18c-7.958 0-14.431 6.473-14.431 14.43 0 7.958 6.474 14.432 14.43 14.432C72.527 75.042 79 68.568 79 60.61c0-7.958-6.474-14.431-14.431-14.431zm0 26.388c-6.593 0-11.957-5.364-11.957-11.957 0-6.594 5.364-11.958 11.957-11.958 6.593 0 11.957 5.364 11.957 11.958 0 6.593-5.364 11.957-11.957 11.957z"></path>
        <path d="M71.166 59.374h-5.36v-5.36a1.237 1.237 0 10-2.474 0v5.36h-5.36a1.237 1.237 0 100 2.474h5.36v5.36a1.237 1.237 0 102.474 0v-5.36h5.36a1.237 1.237 0 100-2.474zM14.431 23.75a3.88 3.88 0 00-3.876 3.875 3.88 3.88 0 003.876 3.876 3.88 3.88 0 003.876-3.876 3.88 3.88 0 00-3.876-3.875zm0 5.277a1.404 1.404 0 01-1.402-1.402c0-.773.63-1.402 1.402-1.402.773 0 1.402.63 1.402 1.402 0 .773-.629 1.402-1.402 1.402zM59.291 23.75H22.348a1.237 1.237 0 100 2.474H59.29a1.237 1.237 0 100-2.474zM24.986 29.027h-2.638a1.237 1.237 0 100 2.474h2.638a1.237 1.237 0 100-2.474zM40.82 29.027h-2.64a1.237 1.237 0 100 2.474h2.64a1.237 1.237 0 100-2.474zM32.903 29.027h-2.639a1.237 1.237 0 100 2.474h2.639a1.237 1.237 0 100-2.474zM14.431 36.944a3.88 3.88 0 00-3.876 3.875 3.88 3.88 0 003.876 3.876 3.88 3.88 0 003.876-3.876 3.88 3.88 0 00-3.876-3.875zm0 5.277a1.404 1.404 0 01-1.402-1.402c0-.773.63-1.402 1.402-1.402.773 0 1.402.63 1.402 1.402 0 .773-.629 1.402-1.402 1.402zM59.291 36.944H22.348a1.237 1.237 0 100 2.474H59.29a1.237 1.237 0 100-2.474zM24.986 42.221h-2.638a1.237 1.237 0 100 2.474h2.638a1.237 1.237 0 100-2.474zM40.82 42.221h-2.64a1.237 1.237 0 100 2.474h2.64a1.237 1.237 0 100-2.474zM32.903 42.221h-2.639a1.237 1.237 0 100 2.474h2.639a1.237 1.237 0 100-2.474zM14.431 50.138a3.88 3.88 0 00-3.876 3.876 3.88 3.88 0 003.876 3.875 3.88 3.88 0 003.876-3.875 3.88 3.88 0 00-3.876-3.876zm0 5.277a1.404 1.404 0 01-1.402-1.401c0-.774.63-1.403 1.402-1.403a1.403 1.403 0 010 2.804zM47.416 50.138H22.348a1.237 1.237 0 100 2.474h25.069a1.237 1.237 0 100-2.474zM24.986 55.415h-2.638a1.237 1.237 0 100 2.474h2.638a1.237 1.237 0 100-2.474zM40.82 55.415h-2.64a1.237 1.237 0 100 2.474h2.64a1.237 1.237 0 100-2.474zM32.903 55.415h-2.639a1.237 1.237 0 100 2.474h2.639a1.237 1.237 0 100-2.474z"></path>
      </g>
      <defs>
        <clipPath id="clip0">
          <path fill="#fff" d="M0 0H79V79H0z"></path>
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoOther = React.memo(Other);
export default MemoOther;
