import * as React from 'react';
import styled from 'styled-components';
import {NavItem} from '@components/blocks/navbar';
import useResponsive from '@hooks/responsive';
import {IndexPageContext} from '@components/context/index-provider';

const navItems = [
  {key: '1', position: 0, to: '/', label: 'Главная'},
  {key: '2', position: 1, to: '#portfolio', label: 'Кейсы'},
  {key: '3', position: 2, to: '#rate', label: 'Стоимость'},
  {key: '4', position: 3, to: '#contacts', label: 'Контакты'},
];

const NavItemLink = styled.a`
  display: block;
  color: #fff;
  text-decoration: none;
  text-align: center;
  font-weight: 500;
  padding: .5em 0;
  font-size: 28px;
  
  &:hover {
    color: #8462fa;
    background-color: white;
  }
  
  &:active {
    background-color: white;
  }
  
  @media (min-width: 1024px) {
    margin-left: 30px;
    font-size: 16px;
    font-weight: 500;
    padding: .5em 0;
    
    &:hover {
      color: #8462fa;
      background-color: transparent;
    }
    
    &:active {
      background-color: transparent;
    }
  }
`;

interface IProps {
  noContacts?: boolean;
  refIntro: HTMLDivElement;
  refPortfolio: HTMLDivElement;
  refRate: HTMLDivElement;
}

const NavbarItemsWebstap = React.memo(({
  noContacts = false,
  refIntro,
  refPortfolio,
  refRate,
}: IProps) => {
  const {setPageScrollPercentByIndex, enableAnimation} = React.useContext(IndexPageContext);
  const isDesktop = useResponsive('(min-width: 1024px)');
  const isDesktopRef = React.useRef(isDesktop);
  isDesktopRef.current = isDesktop;

  const onClick = (position) => (e) => {
    e.preventDefault();
    if (!isDesktopRef.current || !enableAnimation) {
      const needScroll = (position === 3 ? refIntro.current.scrollHeight + refPortfolio.current.scrollHeight + refRate.current.scrollHeight + 40 : position === 2 ? refIntro.current.scrollHeight + refPortfolio.current.scrollHeight : position === 1 ? refIntro.current.scrollHeight : 0);
      window.scrollTo({
        behavior: 'smooth',
        top: needScroll,
      });
    } else {
      setPageScrollPercentByIndex(position, 1);
    }
  };

  const items = noContacts ? navItems.slice(0, 2) : navItems;

  return (
    <>
      {items.map(({to, label, key, position}) => (
        <NavItem key={key}>
          <NavItemLink onClick={onClick(position)} href={to}>
            {label}
          </NavItemLink>
        </NavItem>
      ))}
    </>
  );
});

export default NavbarItemsWebstap;
