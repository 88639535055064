// import * as React from 'react';
import styled from 'styled-components';

export {default as WrapWithCopyByClick} from '@components/modals/modal-elements/wrap-with-copy-by-click';

export const ModalTitle = styled.div`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: bold;
  letter-spacing: 0.02em;
  text-align: center;
  font-size: 26px;  
  
  @media (min-width: 768px) {
    font-size: 30px;  
  }
`;

export const ModalSubTitle = styled.div`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  color: #6029FD;
  text-align: center;
  margin-top: 14px;
  
  @media (min-width: 768px) {
    font-size: 22px;
  }
`;
