import * as React from 'react';
import styled from 'styled-components';
import ReactWave from 'react-wavify';

interface IWrap {
  opacity: number;
}

const Wrap = styled.div<IWrap>`
  z-index: 1;
  position: absolute;
  left: 0;
  bottom: 0;
  height: 600px;
  min-width: 1000px;    
  width: 100vw;
  
  opacity: ${({opacity}) => opacity};
  
  @media (min-width: 1024px) {
    height: 500px;
  }
`;

const SWave = styled(ReactWave)`
  height: 100%;
`;

const color = '#8d48c2';
const options = {
  height: 280,
  amplitude: 30,
  points: 3,
  speed: 0.25,
};

interface IProps {
  paused: boolean;
}

const WaveFooter = React.memo(({
  paused,
}: IProps) => {

  return (
    <Wrap opacity={0.75}>
      <SWave paused={paused} fill={color} options={options} />
    </Wrap>
  );
});

export default WaveFooter;
