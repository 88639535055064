import * as React from 'react';

interface IProps {
}

const AnimateOn = React.memo(({}: IProps) => {
  return (
    <svg width="18px" height="18px" viewBox="0 0 21 21" stroke="white" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19.0421 10.5075C19.0421 12.8945 18.0939 15.1837 16.406 16.8715C14.7182 18.5593 12.429 19.5076 10.0421 19.5076C7.65509 19.5076 5.36589 18.5593 3.67805 16.8715C1.99021 15.1837 1.04199 12.8945 1.04199 10.5075C1.04199 8.12054 1.99021 5.83134 3.67805 4.1435C5.36589 2.45566 7.65509 1.50745 10.0421 1.50745C12.429 1.50745 14.7182 2.45566 16.406 4.1435C18.0939 5.83134 19.0421 8.12054 19.0421 10.5075Z"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4697 10.0745C14.8031 10.267 14.8031 10.7481 14.4697 10.9405L8.20428 14.5579C7.87095 14.7503 7.45428 14.5098 7.45428 14.1249L7.45428 6.89017C7.45428 6.50527 7.87095 6.26471 8.20428 6.45716L14.4697 10.0745Z"
        fill="white"
      />
    </svg>
  );
});

export default AnimateOn;
