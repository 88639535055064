import * as React from 'react';
import styled from 'styled-components';
import {Navbar} from '@components/blocks/navbar';
import SlidesSwitcherItems from '@components/blocks/slides-switcher-items';

const SSlidesSwitcher = styled.div`
  position: fixed;
  left: calc(100vw - 228px);
  z-index: 999;
  margin-top: 60px;
  @media (min-width: 1550px) {
    top: 50vh;
    transform: translate(0, -50%);
    margin-top: 0;
  }
`;

interface IProps {
  getScrollPercent: (value: number) => number;
  isAnimationActive: boolean;
  refIntro: HTMLDivElement;
  refPortfolio: HTMLDivElement;
  refRate: HTMLDivElement;
}

const SlidesSwitcher = ({
  getScrollPercent,
  isAnimationActive,
  refIntro,
  refPortfolio,
  refRate,
}: IProps) => {
  return (
    <SSlidesSwitcher>
      <Navbar>
        <SlidesSwitcherItems
          getScrollPercent={getScrollPercent}
          isAnimationActive={isAnimationActive}
          refIntro={refIntro}
          refPortfolio={refPortfolio}
          refRate={refRate}
        />
      </Navbar>
    </SSlidesSwitcher>
  );
};

export default SlidesSwitcher;
