import * as React from 'react';
import styled from 'styled-components';

const SNavItem = styled.li`
  &:hover {
    a {
      font-weight: 500;
      hr {
        width: 84px;
      }
    }
  }
`;

interface IProps {
  children: React.ReactNode;
  onClick?: (number) => void;
}

const NavItem = ({ children, onClick }: IProps) => {
  return (
    <SNavItem onClick={onClick}>
      {children}
    </SNavItem>
  );
};

export default NavItem;
