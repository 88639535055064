import * as React from 'react';
import styled from 'styled-components';

const { useState, useEffect } = React;

const Span = styled.span<any>`
  --char-index:${({ index }) => index};
`;

export interface IRenderPropsArgs {
  length: number;
  index: number;
  id: number;
  key: string | number;
  className: string;
  value: React.ReactNode;
}

interface IProps {
  children: (props: IRenderPropsArgs) => React.ReactNode;
  content: React.ReactFragment | Array<React.ReactNode> | string;
}

type ElementInTheState = { id: string, value: string };

type StateLetters = Array<ElementInTheState> | Array<React.ReactNode>;

const Letters = ({ children, content }: IProps) => {
  const [letters, setLetters] = useState<StateLetters>([]);

  useEffect(() => {
    if (typeof content === 'string') {
      let iteration = 0;
      const newLetters = content.split('').map((value) => {
        // eslint-disable-next-line no-plusplus
        return ({ id: iteration++, value });
      });
      setLetters(newLetters);
    } else {
      let iteration = 0;

      // @ts-ignore
      const elements = (Array.isArray(content) ? content : content.props.children);

      // @ts-ignore
      const newLetters = elements.reduce<Array<any>>((acc, cur) => {
        if (typeof cur === 'string') {
          const formattedCur = cur.split('').map((value) => {
            // eslint-disable-next-line no-plusplus
            return ({ id: iteration++, value });
          });

          return [...acc, ...formattedCur];
        }

        return [...acc, cur];

      }, []);

      setLetters(newLetters);
    }
  }, [content]);


  const renderSpan = (el) => (children ? children({
    ...el,
    key: el.id,
    length: letters.length,
    className: `char char${el.id + 1}`,
    index: el.id,
  }) : (
    <Span
      index={el.id}
      key={el.id}
      className={`char char${el.id + 1}`}
    >
      {el.value}
    </Span>
  ));


  return (
    <>
      {(letters as any).map((el) => (el.value ? renderSpan(el) : el))}
    </>
  );
};

export default Letters;
