import {useEffect, useCallback} from 'react';

let supportsPassive = false;

type ScrollDirectionHandler = (e: Event) => void;

type UseScrollHandlerParams = {
  downHandler?: ScrollDirectionHandler;
  upHandler?: ScrollDirectionHandler;
  deps?: any[];
};

export const useScrollHandler = ({
  downHandler,
  upHandler,
  deps = [],
}: UseScrollHandlerParams) => {

  const scrollHandler = useCallback((e) => {
    const isDownDirection = e.deltaY > 0;
    if (isDownDirection && downHandler) {
      downHandler(e);
    } else if (!isDownDirection && upHandler) {
      upHandler(e);
    }
  }, deps);

  useEffect(() => {
    const wheelEvent = 'onwheel' in document.createElement('div') ? 'wheel' : 'mousewheel';

    try {
      window.addEventListener('test', null, Object.defineProperty({}, 'passive', {
        // eslint-disable-next-line getter-return
        get () { supportsPassive = true; },
      }));
      // eslint-disable-next-line no-empty
    } catch (e) {}

    const wheelOpt = supportsPassive ? { passive: false } : false;
    window.addEventListener(wheelEvent, scrollHandler, wheelOpt);

    return () => {
      window.removeEventListener(wheelEvent, scrollHandler);
    };
  });
};
