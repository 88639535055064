import * as React from 'react';
import AnimateOff from '@components/elements/icons/animate-off';
import AnimateOn from '@components/elements/icons/animate-on';
import styled, {css} from 'styled-components';
import { Button } from '@components/inputs';

const AnimationBtn = styled.div<{isDesktop: boolean}>`
   background: none;
   align-self: flex-end;
   margin: 0 33px 33px 0;
   visibility: visible;
   z-index: 100;
   ${(props) => !props.isDesktop && css`
       visibility: hidden;
   `}
`;

interface IProps {
  enableAnimation: boolean;
  setEnableAnimation: (value: boolean) => void;
  isDesktop: boolean;
  isEnougthHeight: boolean;
}

const HeaderAnimationBtn = ({enableAnimation, setEnableAnimation, isDesktop, isEnougthHeight}: IProps) => {
  return (
    <AnimationBtn isDesktop={(isDesktop && !isEnougthHeight)}>
      <Button onClick={() => setEnableAnimation(!enableAnimation)} theme="quaternary">
        {enableAnimation ? <><AnimateOff /> Отключить анимации</> : <><AnimateOn /> Включить анимации</>}
      </Button>
    </AnimationBtn>
  );
};
export default HeaderAnimationBtn;
