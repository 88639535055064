import * as React from "react";

function LogoMiniDark(props) {
  return (
    <svg viewBox="0 0 205 62" fill="none" {...props}>
      <path
        d="M84.55 43.45h-3.18l-5.87-17.6h4l3.6 11.8 4-11.85h2.8l4 11.85 3.6-11.8h3.92l-5.9 17.6h-3.18l-3.82-11.68-3.97 11.68zM116.9 43.35h-13.13v-17.5h12.85v3.23h-9.25v3.38h7.45v3.17h-7.45v4.5h9.52l.01 3.22zM128.57 43.35h-8.8v-17.5H128a6 6 0 013.86 1.15 3.892 3.892 0 011.44 3.23 3.723 3.723 0 01-.62 2.17 3.586 3.586 0 01-1.58 1.3 4.48 4.48 0 012.5 1.55c.705.886 1.061 2 1 3.13A4.465 4.465 0 01133 42a6.63 6.63 0 01-4.43 1.35zm-5.2-14.5v3.85h4.05a2.79 2.79 0 001.76-.5 1.683 1.683 0 00.64-1.4 1.732 1.732 0 00-.64-1.44 2.74 2.74 0 00-1.76-.51h-4.05zm0 6.83v4.57h4.52a3.33 3.33 0 002.1-.59 2.003 2.003 0 00.75-1.69 2.05 2.05 0 00-.75-1.7 3.28 3.28 0 00-2.1-.6l-4.52.01zM149.85 43.65a12.289 12.289 0 01-3.62-.54 8.6 8.6 0 01-2.77-1.34v-1.32a9.257 9.257 0 002.67 1.48c1.161.43 2.392.643 3.63.63a6.38 6.38 0 003.73-1 3.196 3.196 0 001.48-2.75 3.098 3.098 0 00-1-2.32 4.484 4.484 0 00-1.2-.73 10.23 10.23 0 00-1.4-.55c-.233-.087-.783-.247-1.65-.48l-1.87-.55c-.6-.233-1.11-.45-1.53-.65a4.26 4.26 0 01-1.7-1.41 4.49 4.49 0 01-.6-2.21 3.797 3.797 0 011.52-3.07 6.63 6.63 0 014.27-1.25A9.53 9.53 0 01155.1 27v1.23a9.2 9.2 0 00-5.25-1.6 5.729 5.729 0 00-3.48.91 2.838 2.838 0 00.17 4.79c.317.191.652.352 1 .48.353.146.713.273 1.08.38l1.2.35.53.15 1.7.52a8.678 8.678 0 011.52.65c.51.216.977.52 1.38.9a4.067 4.067 0 011.2 3 4.17 4.17 0 01-1.83 3.6 7.65 7.65 0 01-4.47 1.29zM165.65 43.35h-1.15V26.93h-6.27v-1.08h13.7v1.08h-6.27l-.01 16.42zM172.37 43.35h-1.22l7.95-17.6h1.08l7.9 17.6h-1.2l-2.32-5.15h-9.86l-2.33 5.15zm7.25-16.1l-4.52 10h9l-4.48-10zM192.67 43.35h-1.15v-17.5h7.28a5.759 5.759 0 014 1.27 4.818 4.818 0 011.438 3.43 4.808 4.808 0 01-1.438 3.43 5.73 5.73 0 01-4 1.29h-6.13v8.08zm0-16.42v7.27h6a4.87 4.87 0 003.21-1 3.747 3.747 0 001.136-2.685 3.743 3.743 0 00-1.136-2.685 4.88 4.88 0 00-3.21-.95l-6 .05z"
        fill="#4B1B64"
      />
      <path d="M68.72 56.13V13.07" stroke="#323232" strokeWidth={0.75} />
      <path
        d="M29.77.13l12.72 7.34-29.37 16.96L.4 17.09 29.77.13z"
        fill="#FACCFF"
      />
      <path
        d="M42.48 22.18l12.71 7.35-29.36 16.95-12.72-7.34 29.37-16.96z"
        fill="#F7A9FF"
      />
      <path
        d="M13.12 24.43v14.68l29.37-16.95V7.47L13.12 24.43z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M25.83 46.48v14.69l29.36-16.96V29.53L25.83 46.48z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M56.79 53.74v-1.5"
        stroke="#4B1B64"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M56.6 49c-1.54-14.8-11.82-19-17.3-18.15a6.16 6.16 0 00-2.29.88"
        stroke="#4B1B64"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeDasharray="3.2 3.2"
      />
      <path
        d="M35.73 32.69c-.371.335-.722.693-1.05 1.07"
        stroke="#4B1B64"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path d="M34.66 33.89v-1.5" stroke="#4B1B64" strokeLinecap="round" />
      <path
        d="M34.58 29.49C33.31 8.62 20.07 1.83 12 2.11"
        stroke="#4B1B64"
        strokeLinecap="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M10.54 2.24h-.06a7.84 7.84 0 00-1.39.36"
        stroke="#4B1B64"
        strokeLinecap="round"
      />
      <path d="M9.21.33L4.09 6.28l7.41-2.59L9.21.33z" fill="#4B1B64" />
      <path
        d="M.4 17.09v29.39l25.43 14.69V46.48l-12.71-7.37V24.43L.4 17.09z"
        fill="#7132BA"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={13.12}
          y1={23.29}
          x2={42.49}
          y2={23.29}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E4FC9" />
          <stop offset={1} stopColor="#6129DD" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={25.82}
          y1={45.35}
          x2={55.19}
          y2={45.35}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#692D8A" />
          <stop offset={1} stopColor="#552191" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoLogoMiniDark = React.memo(LogoMiniDark);
export default MemoLogoMiniDark;
