/* eslint-disable max-len */
import * as React from 'react';
import styled, {css} from 'styled-components';
import debounce from 'lodash.debounce';
import './intro-illustration.css';

const transitionDuration = 750;
const incrementStepDelay = 250;

const Svg = styled.svg`
  width: 100%;
  max-width: 300px;
  user-select: none;
  margin-bottom: 20px;
  -webkit-tap-highlight-color: transparent;
  overflow: visible;
  @media (min-width: 1024px) {
    margin-bottom: 0;
    margin-top: 0;
    max-width: 490px;
  }
`;

const layersStates = {
  default: css`
    transform: translate(10px, -100px);
    opacity: 0;
  `,
  entered: css`
    transform: translate(0, 0);
    opacity: 1;  
  `,
  'ready-to-hover': css`
    transform: translate(0, 0);
    opacity: 1;
  `,
  animate: css`
    transform: translate(0, 0);
    animation: waveTransform 2500ms ease-in-out infinite calc(var(--index) * 100ms);

    @keyframes waveTransform {
      0% {
        transform: translate(0, 0);
      }
      25% {
        transform: translate(10px, -50px);
      }
      50% {
        transform: translate(0, 0);
      }
    }
  `,
};

interface ILayer {
  index: number;
  animationState: 'default' | 'entered' | 'animate' | 'ready-to-hover';
  isDesktop: boolean;
  enableAnimation: boolean;
}

const GLayer = styled.g<ILayer>`
  --index: ${({index}) => index};
  user-select: none;
  transition-duration: ${transitionDuration}ms;
  transition-timing-function: ease-out;
  transition-property: transform, opacity;
  transition-delay: calc(var(--index) * ${incrementStepDelay}ms);
  ${({
    animationState, isDesktop, enableAnimation,
  }) => isDesktop && enableAnimation ? layersStates[animationState] : layersStates.entered}
`;

interface IProps {
  isDesktop: boolean;
  enableAnimation: boolean;
}

type IStateAnimationState = 'default' | 'entered' | 'ready-to-hover' | 'animate';

const IntroIllustration = React.memo(({
  isDesktop,
  enableAnimation,
}: IProps) => {

  const [animationState, setAnimationState] = React.useState<IStateAnimationState>('default');

  const clearAnimate = React.useRef(debounce(() => {
    setAnimationState('ready-to-hover');
  }, 2200)).current;

  React.useEffect(() => {
    const preEnteredWait = 1500;
    const enteredWait = 2000;

    const timerId1 = setTimeout(() => setAnimationState('entered'), preEnteredWait);
    const timerId2 = setTimeout(() => setAnimationState('ready-to-hover'), preEnteredWait + enteredWait);

    return () => {
      clearTimeout(timerId1);
      clearTimeout(timerId2);
      clearAnimate.cancel();
    };
  }, []);

  const onMouseEnter = () => {
    if (animationState === 'ready-to-hover') {
      setAnimationState('animate');
      clearAnimate();
    }
  };

  return (
    <Svg
      className={`introIllustration-${animationState}`}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="100 50 520 420"
      onMouseEnter={onMouseEnter}
    >
      <GLayer isDesktop={isDesktop} index={0} animationState={animationState} enableAnimation={enableAnimation}>
        <g className="cls-26">
          <path
            className="cls-1"
            d="M444.38,181.08l-21.27-12.51-22.52-12.51V76.27c0-.28,5.19-7.2,8.84-5l133.78,79.8v87.57Z"
          />
          <polyline className="cls-1" points="400.59 76.27 400.59 65.75 411.13 72.27" />
        </g>
        <text className="cls-29" transform="matrix(1, 0.58, 0, 1, 454.79, 133.77)">С
          <tspan className="cls-30" x="13.02" y="0">та</tspan>
          <tspan className="cls-31" x="34.12" y="0">р</tspan>
          <tspan className="cls-32" x="46.41" y="0">т</tspan>
        </text>
        <path
          className="cls-41"
          d="M560,250.55a14.21,14.21,0,0,1-4.23-3.79,18.28,18.28,0,0,1-2.69-5,15.68,15.68,0,0,1-.92-5.21,8.34,8.34,0,0,1,.88-4.11,3.64,3.64,0,0,1,2.76-1.91,6.62,6.62,0,0,1,4.19,1.1,14.71,14.71,0,0,1,5.69,6.06,16.65,16.65,0,0,1,2.13,7.9q0,3.92-2.13,5.44T560,250.55Zm-3-6.94a7.62,7.62,0,0,0,3,3.44q1.87,1.08,3,0a4.87,4.87,0,0,0,1.12-3.59,11.11,11.11,0,0,0-1.12-4.85,7.38,7.38,0,0,0-3-3.45q-1.87-1.08-3,0a4.72,4.72,0,0,0-1.12,3.56A11.37,11.37,0,0,0,557,243.61Z"
        />
        <path
          className="cls-41"
          d="M575.64,259.61A20.29,20.29,0,0,1,570.1,255V251.4a18.69,18.69,0,0,0,5.32,4.74,4.19,4.19,0,0,0,2.73.76q1.06-.2,1.06-1.61a4.69,4.69,0,0,0-1-2.71,8.15,8.15,0,0,0-2.62-2.33,8.06,8.06,0,0,0-2.57-1v-2.47l4.34-1.5-6.7-3.87V238.2l11.48,6.63v2.8L578,249a14.26,14.26,0,0,1,3.43,3.77,8.45,8.45,0,0,1,1.48,4.65q0,2.91-2.1,3.43T575.64,259.61Z"
        />
      </GLayer>

      <GLayer isDesktop={isDesktop} index={1} animationState={animationState} enableAnimation={enableAnimation}>
        <path
          className="cls-41"
          d="M530.84,274.53a14.22,14.22,0,0,1-4.23-3.79,18.25,18.25,0,0,1-2.69-5,15.7,15.7,0,0,1-.92-5.21,8.35,8.35,0,0,1,.88-4.11,3.65,3.65,0,0,1,2.76-1.91,6.62,6.62,0,0,1,4.19,1.1,14.71,14.71,0,0,1,5.69,6.06,16.64,16.64,0,0,1,2.13,7.9q0,3.92-2.13,5.44T530.84,274.53Zm-3-6.94a7.63,7.63,0,0,0,3,3.44q1.87,1.08,3,0a4.87,4.87,0,0,0,1.12-3.59,11.11,11.11,0,0,0-1.12-4.85,7.37,7.37,0,0,0-3-3.45q-1.87-1.08-3,0a4.72,4.72,0,0,0-1.12,3.56A11.37,11.37,0,0,0,527.85,267.58Z"
        />
        <path
          className="cls-41"
          d="M553.81,287.43,540.88,280v-2.73l5.35-1.77a7.42,7.42,0,0,0,2.52-1.25,2.21,2.21,0,0,0,.73-1.79,4,4,0,0,0-.86-2.35,7.16,7.16,0,0,0-2.26-2,6,6,0,0,0-4.91-.76V263.5q2.21-.47,5.3,1.32a14.62,14.62,0,0,1,4.52,4.14,8.77,8.77,0,0,1,1.92,5.13,4.31,4.31,0,0,1-.86,2.91,6.79,6.79,0,0,1-3,1.73l-3.12,1.06,7.58,4.38Z"
        />
        <g className="cls-27">
          <path
            className="cls-1"
            d="M411.9,206.72l-21.27-12.51L368.11,181.7V101.91c0-.28,5.19-7.2,8.84-5l133.78,79.8v87.57Z"
          />
          <polyline className="cls-1" points="368.11 101.91 368.11 91.39 378.65 97.91" />
        </g>
        <text className="cls-33" transform="matrix(1, 0.58, 0, 1, 404.91, 150.89)">Дизайн</text>
      </GLayer>

      <GLayer isDesktop={isDesktop} index={2} animationState={animationState} enableAnimation={enableAnimation}>
        <path
          className="cls-28"
          d="M484.83,204.62,314.67,103.12a7.6,7.6,0,0,0-11.41,5.45,7.88,7.88,0,0,0-.07,1.08v99.22a7.6,7.6,0,0,0,3.91,6.64l25.36,14.09,27.64,16.26,117,68.14a7.6,7.6,0,0,0,11.42-6.56V211.14A7.6,7.6,0,0,0,484.83,204.62ZM473,218.28V306l-107-62.28-23-13.54-24.37-13.54V118.9L330.09,126h0L473,211.22Z"
        />
        <polygon
          className="cls-1"
          points="318.68 130.29 318.68 216.64 343.06 230.18 366.07 243.72 473.04 306 473.04 218.28 473.04 211.22 330.09 125.96 318.68 130.29"
        />
        <text className="cls-34" transform="matrix(1, 0.58, 0, 1, 366.78, 185.87)">Идея</text>
        <path
          className="cls-1"
          d="M328.25,124.86l1.84,1.1h0l-11.41-7.05v11.39C318.68,130,324.3,122.5,328.25,124.86Z"
        />
        <path className="cls-1" d="M328.25,124.86c-4-2.36-9.57,5.13-9.57,5.43L330.09,126Z" />
        <path
          className="cls-41"
          d="M504.12,328.17a14.22,14.22,0,0,1-4.23-3.79,18.23,18.23,0,0,1-2.69-5,15.68,15.68,0,0,1-.92-5.21,8.35,8.35,0,0,1,.88-4.11,3.64,3.64,0,0,1,2.76-1.91,6.62,6.62,0,0,1,4.19,1.1,14.71,14.71,0,0,1,5.69,6.06,16.66,16.66,0,0,1,2.13,7.9q0,3.92-2.13,5.44T504.12,328.17Zm-3-6.94a7.62,7.62,0,0,0,3,3.44q1.87,1.08,3,0a4.87,4.87,0,0,0,1.12-3.59,11.11,11.11,0,0,0-1.12-4.85,7.38,7.38,0,0,0-3-3.45q-1.87-1.08-3,0a4.72,4.72,0,0,0-1.12,3.56A11.39,11.39,0,0,0,501.13,321.22Z"
        />
        <path className="cls-41" d="M521.28,337.71l-3.66-2.11V321.42l-3.84-.27v-3.66l4.26.17,3.25,1.87Z" />
      </GLayer>

      <GLayer isDesktop={isDesktop} index={3} animationState={animationState} enableAnimation={enableAnimation}>
        <g id="Подставка">
          <path
            className="cls-24"
            d="M319.72,288.89l65.13-37,49.58,31.55-73.62,42.07L319.71,302A7.53,7.53,0,0,1,319.72,288.89Z"
          />
          <polygon className="cls-24" points="434.43 283.41 434.43 280.4 431.94 281.82 434.43 283.41" />
          <path
            className="cls-25"
            d="M319.72,285.89l65.13-37,49.58,31.55-73.62,42.07L319.71,299A7.53,7.53,0,0,1,319.72,285.89Z"
          />
        </g>
        <g id="Браузер">
          <path
            className="cls-35"
            d="M336.78,281.38l-25.54-15-27-15V155.52a7.28,7.28,0,0,1,.07-1,7,7,0,0,1,10.55-5l3.06,1.82,157.6,94V350.48Z"
          />
          <polygon className="cls-35" points="297.89 151.36 284.19 143.14 284.19 156.15 297.89 151.36" />
          <polyline className="cls-36" points="371.2 301.42 455.46 350.48 455.46 252.75" />
        </g>
        <g id="Контент">
          <g className="cls-27">
            <polygon
              className="cls-37"
              points="300.64 197.48 347.31 224.93 347.31 227.68 300.64 200.22 300.64 197.48"
            />
            <polygon
              className="cls-37"
              points="300.64 202.97 335.86 223.69 335.94 226.48 300.64 205.72 300.64 202.97"
            />
            <polygon
              className="cls-37"
              points="300.64 208.46 335.86 229.18 335.94 231.97 300.64 211.21 300.64 208.46"
            />
            <polygon className="cls-37" points="300.64 213.95 326.96 229.44 327.01 232.21 300.64 216.7 300.64 213.95" />
            <polygon className="cls-37" points="300.64 219.44 326.96 234.93 327.01 237.7 300.64 222.19 300.64 219.44" />
          </g>
          <path
            className="cls-38"
            d="M303,232.45l25,14.27a3.62,3.62,0,0,1,1.82,3.14v3.5a1.59,1.59,0,0,1-2.38,1.38l-25-14.27a3.62,3.62,0,0,1-1.82-3.14v-3.5A1.59,1.59,0,0,1,303,232.45Z"
          />
          <text className="cls-39" transform="matrix(1, 0.58, 0, 1, 305.41, 239.5)">LEARN MORE</text>
          <path
            className="cls-40"
            d="M399.29,233.62l22.83,13.17a6.39,6.39,0,0,1,3.2,5.54v27.34a6.39,6.39,0,0,1-9.75,5.45l-22.83-14.05a6.39,6.39,0,0,1-3-5.45V239.16A6.39,6.39,0,0,1,399.29,233.62Z"
          />
          <path
            className="cls-40"
            d="M425.32,253.69v46.05a8.76,8.76,0,0,1-12.91,7.71L394.3,297.7a8.76,8.76,0,0,1-4.6-7.71V243.24a8.76,8.76,0,0,1,13.13-7.58l18.11,10.45A8.76,8.76,0,0,1,425.32,253.69Z"
          />
          <path
            className="cls-41"
            d="M395.18,235,418,248.16a6.39,6.39,0,0,1,3.2,5.54V281a6.39,6.39,0,0,1-9.75,5.45l-22.83-14.05a6.39,6.39,0,0,1-3-5.45V240.53A6.39,6.39,0,0,1,395.18,235Z"
          />
          <path
            className="cls-41"
            d="M421.21,255.06v46.05a8.76,8.76,0,0,1-12.91,7.71l-18.11-9.75a8.76,8.76,0,0,1-4.6-7.71V244.61A8.76,8.76,0,0,1,398.71,237l18.11,10.45A8.76,8.76,0,0,1,421.21,255.06Z"
          />
          <polygon className="cls-38" points="300.64 181.5 354.82 211.84 354.82 221.24 300.64 190.17 300.64 181.5" />
          <path
            className="cls-42"
            d="M391.35,284.83l23.32,13.33a3.38,3.38,0,0,1,1.7,2.93v3.26a1.48,1.48,0,0,1-2.22,1.29l-23.32-13.33a3.38,3.38,0,0,1-1.7-2.93v-3.26A1.48,1.48,0,0,1,391.35,284.83Z"
          />
          <polygon className="cls-43" points="455.46 252.75 284.19 150.85 284.19 143.14 455.46 245.32 455.46 252.75" />
          <circle className="cls-44" cx="291.89" cy="151.99" r="1.44" />
          <circle className="cls-45" cx="295.49" cy="154.16" r="1.44" />
          <circle className="cls-46" cx="298.89" cy="156.13" r="1.44" />
          <polygon className="cls-41" points="340.41 178.14 391.06 208.34 391.06 212.7 340.41 182.31 340.41 178.14" />
          <g className="cls-27">
            <polygon
              className="cls-47"
              points="392.16 277.75 413.23 289.75 413.23 289.37 392.16 277.55 392.16 277.75"
            />
            <polygon
              className="cls-47"
              points="392.16 270.37 413.23 282.37 413.23 281.99 392.16 270.16 392.16 270.37"
            />
            <polygon
              className="cls-47"
              points="392.16 262.93 413.23 274.93 413.23 274.55 392.16 262.73 392.16 262.93"
            />
          </g>
          <text className="cls-48" transform="matrix(1, 0.58, 0, 1, 395.82, 259.02)">
            <tspan className="cls-49">W</tspan>
            <tspan x="3.17" y="0">el</tspan>
            <tspan className="cls-50" x="5.98" y="0">c</tspan>
            <tspan x="7.57" y="0">ome!</tspan>
          </text>
          <text className="cls-51" transform="matrix(1, 0.58, 0, 1, 396.51, 293.02)">JOIN</text>
          <path
            className="cls-46"
            d="M409.29,253.05c0,3.25-2.64,4.93-5.89,3.75a9.32,9.32,0,0,1-5.89-8c0-3.25,2.64-4.93,5.89-3.75A9.32,9.32,0,0,1,409.29,253.05Z"
          />
        </g>
      </GLayer>

      <GLayer isDesktop={isDesktop} index={4} animationState={animationState} id="Слой_4" data-name="Слой 4" enableAnimation={enableAnimation}>
        <polygon className="cls-1" points="165.43 276.32 165.43 180.13 245.83 228.09 245.83 322.73 165.43 276.32" />
        <path
          className="cls-2"
          d="M180.24,208.72a9.49,9.49,0,0,1,6,8.19c0,3.31-2.69,5-6,3.82a9.49,9.49,0,0,1-6-8.19C174.24,209.23,176.93,207.52,180.24,208.72Z"
        />
        <polyline className="cls-3" points="183.36 213.46 179.52 216.38 177.12 213.59" />
        <path
          className="cls-2"
          d="M181.5,227.73a9.49,9.49,0,0,1,6,8.19c0,3.31-2.69,5-6,3.82a9.49,9.49,0,0,1-6-8.19C175.5,228.24,178.18,226.53,181.5,227.73Z"
        />
        <polyline className="cls-3" points="184.62 232.47 180.78 235.39 178.38 232.6" />
        <path
          className="cls-2"
          d="M181.5,245.43a9.49,9.49,0,0,1,6,8.19c0,3.31-2.69,5-6,3.82a9.49,9.49,0,0,1-6-8.19C175.5,245.93,178.18,244.22,181.5,245.43Z"
        />
        <polyline className="cls-3" points="184.62 250.17 180.78 253.09 178.38 250.3" />
        <path
          className="cls-2"
          d="M181.5,262.61a9.49,9.49,0,0,1,6,8.19c0,3.31-2.69,5-6,3.82a9.49,9.49,0,0,1-6-8.19C175.5,263.11,178.18,261.4,181.5,262.61Z"
        />
        <polyline className="cls-3" points="184.62 267.35 180.78 270.27 178.38 267.47" />
        <polygon className="cls-4" points="189.59 216.28 220.62 234.34 220.62 238.89 189.59 220.46 189.59 216.28" />
        <polygon className="cls-4" points="190.12 233.57 221.15 251.63 221.15 256.18 190.12 237.75 190.12 233.57" />
        <polygon className="cls-4" points="190.12 251.95 221.15 270.01 221.15 274.56 190.12 256.13 190.12 251.95" />
        <polygon className="cls-4" points="189.26 268.46 220.29 286.52 220.29 291.07 189.26 272.64 189.26 268.46" />
      </GLayer>

      <GLayer isDesktop={isDesktop} index={5} animationState={animationState} id="Зеркало" enableAnimation={enableAnimation}>
        <path
          className="cls-5"
          d="M131.51,322.76,354.66,451a11.5,11.5,0,0,0,17.22-10v-69a11.5,11.5,0,0,0-5.71-9.93L137.44,228.93a11.5,11.5,0,0,0-17.28,9.93V310a11.5,11.5,0,0,0,6.35,10.28l4.41,2.21Z"
        />
        <g className="cls-6">
          <polygon className="cls-1" points="121.83 251.14 149.28 235.17 145.53 232.98 121.83 246.66 121.83 251.14" />
          <polygon className="cls-1" points="121.83 274.15 169.23 246.79 192.72 260.47 121.83 301.64 121.83 274.15" />
          <polygon className="cls-1" points="121.83 308.92 199.14 264.2 203.08 266.5 122.27 312.54 121.83 308.92" />
          <polygon className="cls-1" points="157.4 336.08 240.63 288.37 264.27 301.64 180.29 350.35 157.4 336.08" />
          <polygon className="cls-1" points="205.12 363.5 287.74 315.8 293.06 318.9 210.21 366.47 205.12 363.5" />
          <polygon className="cls-1" points="190.13 354.89 272.88 307.15 280.83 311.78 199.11 360.05 190.13 354.89" />
          <polygon className="cls-1" points="324.01 431.83 371.2 405.05 371.2 416.14 334.96 438.13 324.01 431.83" />
          <polygon className="cls-1" points="348.18 445.72 371.2 432.54 371.2 436.52 351.92 447.88 348.18 445.72" />
          <polygon className="cls-1" points="252.83 390.93 334.57 343.74 358.84 357.21 277.34 404.83 252.83 390.93" />
        </g>
        <path
          className="cls-7"
          d="M135.13,322.1l221.06,127a11.39,11.39,0,0,0,17.06-9.87V371a11.39,11.39,0,0,0-5.66-9.84L141,229.15A11.39,11.39,0,0,0,123.89,239v70.43a11.39,11.39,0,0,0,6.29,10.19l4.37,2.19Z"
        />
        <path
          className="cls-8"
          d="M133.08,322.1l221.06,127a11.39,11.39,0,0,0,17.06-9.87V371a11.39,11.39,0,0,0-5.66-9.84L139,229.15A11.39,11.39,0,0,0,121.83,239v70.43a11.39,11.39,0,0,0,6.29,10.19l4.37,2.19Z"
        />
        <g className="cls-9">
          <polygon className="cls-10" points="136.04 264.59 176.29 287.83 176.29 320.45 136.04 297.21 136.04 264.59" />
          <path
            className="cls-11"
            d="M158.61,270.33l-.74.75c4,4.88,11.78,13.48,11.86,13.56l.65-.85C170.3,283.71,162.6,275.16,158.61,270.33Z"
          />
          <path
            className="cls-11"
            d="M142,267.39l.65,1.6c.08,0,7.82.34,11.86.13l-.74-1.6C149.73,267.73,142,267.4,142,267.39Z"
          />
          <path
            className="cls-12"
            d="M151.31,283.81a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C150.62,283.72,150.93,283.59,151.31,283.81Z"
          />
          <path
            className="cls-12"
            d="M156.17,286.62a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C155.48,286.53,155.79,286.4,156.17,286.62Z"
          />
          <path
            className="cls-12"
            d="M161,289.42a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C160.33,289.33,160.64,289.2,161,289.42Z"
          />
          <path
            className="cls-12"
            d="M165.89,292.23a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C165.19,292.14,165.5,292,165.89,292.23Z"
          />
          <path
            className="cls-12"
            d="M170.74,295a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C170,294.94,170.36,294.81,170.74,295Z"
          />
          <path
            className="cls-13"
            d="M141.59,283.76a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C140.9,283.67,141.21,283.53,141.59,283.76Z"
          />
          <path
            className="cls-14"
            d="M146.45,286.56a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C145.76,286.47,146.07,286.34,146.45,286.56Z"
          />
          <path
            className="cls-15"
            d="M141.59,278.2a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C140.9,278.11,141.21,278,141.59,278.2Z"
          />
          <path
            className="cls-15"
            d="M146.45,281a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C145.76,280.92,146.07,280.79,146.45,281Z"
          />
          <path
            className="cls-12"
            d="M151.31,289.37a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C150.62,289.28,150.93,289.14,151.31,289.37Z"
          />
          <path
            className="cls-12"
            d="M156.17,292.17a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C155.48,292.08,155.79,291.95,156.17,292.17Z"
          />
          <path
            className="cls-12"
            d="M161,295a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C160.33,294.89,160.64,294.75,161,295Z"
          />
          <path
            className="cls-12"
            d="M165.89,297.78a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C165.19,297.69,165.5,297.56,165.89,297.78Z"
          />
          <path
            className="cls-12"
            d="M170.74,300.59a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C170,300.5,170.36,300.36,170.74,300.59Z"
          />
          <path
            className="cls-13"
            d="M141.59,288.61a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C140.9,288.52,141.21,288.39,141.59,288.61Z"
          />
          <path
            className="cls-14"
            d="M146.45,291.42a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C145.76,291.33,146.07,291.2,146.45,291.42Z"
          />
          <path
            className="cls-12"
            d="M151.31,294.22a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C150.62,294.13,150.93,294,151.31,294.22Z"
          />
          <path
            className="cls-12"
            d="M156.17,297a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C155.48,296.94,155.79,296.81,156.17,297Z"
          />
          <path
            className="cls-12"
            d="M161,299.83a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C160.33,299.74,160.64,299.61,161,299.83Z"
          />
          <path
            className="cls-12"
            d="M165.89,302.64a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C165.19,302.55,165.5,302.42,165.89,302.64Z"
          />
          <path
            className="cls-12"
            d="M170.74,305.44a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C170,305.35,170.36,305.22,170.74,305.44Z"
          />
          <path
            className="cls-15"
            d="M165.89,308.19a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C165.19,308.1,165.5,308,165.89,308.19Z"
          />
          <path
            className="cls-15"
            d="M170.74,311a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C170,310.91,170.36,310.77,170.74,311Z"
          />
          <path
            className="cls-13"
            d="M141.59,294.17a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C140.9,294.08,141.21,293.94,141.59,294.17Z"
          />
          <path
            className="cls-14"
            d="M146.45,297a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C145.76,296.88,146.07,296.75,146.45,297Z"
          />
          <path
            className="cls-12"
            d="M151.31,299.78a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C150.62,299.69,150.93,299.55,151.31,299.78Z"
          />
          <path
            className="cls-12"
            d="M156.17,302.58a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C155.48,302.49,155.79,302.36,156.17,302.58Z"
          />
          <path
            className="cls-12"
            d="M161,305.39a1.44,1.44,0,0,1,.69,1.09c0,.38-.31.51-.69.29a1.44,1.44,0,0,1-.69-1.09C160.33,305.3,160.64,305.16,161,305.39Z"
          />
          <path
            className="cls-16"
            d="M156.17,268.57a5.76,5.76,0,0,1,2.78,4.38c0,1.53-1.24,2.06-2.78,1.17a5.76,5.76,0,0,1-2.78-4.38C153.39,268.21,154.64,267.69,156.17,268.57Z"
          />
          <polygon className="cls-12" points="136.04 264.59 176.29 287.83 176.29 292.69 136.04 269.45 136.04 264.59" />
        </g>
        <path
          className="cls-17"
          d="M228.06,328.79c-3.5-2.69-5.1-7.7-3-9.36l1.48-1.16-3.2-5-2,.2c-2.95.3-7-3.78-7.51-7.61l-.38-2.69-4.52-2.61-.59,2.71c-.66,3-4.6,2.4-7.48-1.22l-2.57-3.22-3.2,1.35L196.6,303c2.11,4.09.5,7.25-3,5.9l-2.36-.91v4.52l2.47,1.81c3.52,2.57,5.27,7.61,3.28,9.41l-1.35,1.21,3.2,5,2-.31c2.87-.45,7,3.51,7.63,7.4l.45,2.62,4.52,2.61.27-1.58c.55-3.28,4.73-2.6,7.66,1.25l1.42,1.86,3.2-1.35-1.35-2.76c-2-4.1-.24-7.11,3.28-5.62l2.47,1v-4.52Zm-16.85-3.63a7,7,0,0,1-3.39-5.35c0-1.87,1.52-2.51,3.39-1.43a7,7,0,0,1,3.39,5.35C214.6,325.61,213.08,326.25,211.21,325.17Z"
        />
        <path
          className="cls-18"
          d="M211.16,315.46c-3.45-2-6.25-.81-6.25,2.64s2.8,7.87,6.25,9.86,6.25.81,6.25-2.64S214.61,317.46,211.16,315.46Zm0,9.21a6.14,6.14,0,0,1-3-4.67c0-1.64,1.33-2.2,3-1.25a6.14,6.14,0,0,1,3,4.67C214.12,325.06,212.8,325.62,211.16,324.68Z"
        />
        <g className="cls-9">
          <polygon
            className="cls-19"
            points="280.67 348.79 271.07 333.64 249.82 321.37 249.82 361.14 280.67 378.95 280.67 348.79"
          />
          <polygon className="cls-20" points="271.07 333.64 271.07 343.24 280.67 348.79 271.07 333.64" />
          <path
            className="cls-20"
            d="M256,335.91l6.17,3.56c.38.22.69.09.69-.29a1.42,1.42,0,0,0-.69-1.08L256,334.54c-.38-.22-.69-.09-.69.29A1.42,1.42,0,0,0,256,335.91Z"
          />
          <path
            className="cls-20"
            d="M256,341.39l3.43,2c.38.22.69.09.69-.29a1.42,1.42,0,0,0-.69-1.08L256,340c-.38-.22-.69-.09-.69.29A1.42,1.42,0,0,0,256,341.39Z"
          />
          <path
            className="cls-20"
            d="M271.07,348.73l-4.8-2.77c-.38-.22-.69-.09-.69.29a1.42,1.42,0,0,0,.69,1.08l4.8,2.77c.38.22.69.09.69-.29A1.42,1.42,0,0,0,271.07,348.73Z"
          />
          <path
            className="cls-20"
            d="M262.36,343.9a.36.36,0,0,0-.2.37,1.18,1.18,0,0,0,.2.6,1.54,1.54,0,0,0,.49.48.55.55,0,0,0,.49.08.37.37,0,0,0,.2-.37,1.23,1.23,0,0,0-.2-.6C263.07,344.05,262.61,343.79,262.36,343.9Z"
          />
          <path
            className="cls-20"
            d="M275,351.19c-.26-.4-.71-.67-1-.56a.39.39,0,0,0-.2.37,1.23,1.23,0,0,0,.2.6,1.54,1.54,0,0,0,.49.48.6.6,0,0,0,.49.08.39.39,0,0,0,.2-.37A1.23,1.23,0,0,0,275,351.19Z"
          />
          <path
            className="cls-20"
            d="M264.22,350.26l-4.8-2.77c-.38-.22-.69-.09-.69.29a1.42,1.42,0,0,0,.69,1.08l4.8,2.77c.38.22.69.09.69-.29A1.42,1.42,0,0,0,264.22,350.26Z"
          />
          <path
            className="cls-20"
            d="M255.5,345.42a.37.37,0,0,0-.2.37,1.23,1.23,0,0,0,.2.6,1.54,1.54,0,0,0,.49.48.6.6,0,0,0,.49.08.37.37,0,0,0,.2-.37,1.18,1.18,0,0,0-.2-.6C256.22,345.58,255.76,345.32,255.5,345.42Z"
          />
          <path
            className="cls-11"
            d="M258.15,353c-1.5-.09-2,1.12-2.19,2.56l-.85-1.2c-.67-1-1.65-.55-1,.41l1.92,2.72c.45.64,1.15.81,1.17.19.05-1.46.12-3.18,1.61-3.1C259.62,354.66,258.93,353.08,258.15,353Z"
          />
          <path
            className="cls-11"
            d="M264.55,360.22a3.29,3.29,0,0,0-1.84-1.81c-.94-.28-2,.22-2.7-1.49-.39-1-1.57-1-1.18,0a5.31,5.31,0,0,0,1.83,2.38,3,3,0,0,0,1.16.51c.28.06,1.32-.15,1.55.41C263.8,361.25,265,361.24,264.55,360.22Z"
          />
          <path
            className="cls-21"
            d="M272.82,354.6h0a2.2,2.2,0,0,0,1.54,1.53h0c1,.1,2,1.67,1.5,2.36h0c-.29.44,0,1.32.64,1.91h0c1,.93,1,2.34,0,2.12h0c-.63-.14-.92.41-.64,1.17h0c.45,1.22-.54,1.64-1.5.63h0c-.6-.63-1.32-.75-1.54-.25h0c-.35.79-1.76,0-2.12-1.22h0a2.2,2.2,0,0,0-1.54-1.53h0c-1-.1-2-1.67-1.5-2.36h0c.29-.44,0-1.32-.64-1.91h0c-1-.93-1-2.34,0-2.12h0c.63.14.92-.41.64-1.17h0c-.45-1.22.54-1.64,1.5-.63h0c.6.63,1.32.75,1.54.25h0C271.05,352.58,272.46,353.4,272.82,354.6Z"
          />
          <path
            className="cls-22"
            d="M274.36,364.32c-.6-.63-1.32-.75-1.54-.25-.35.79-1.76,0-2.12-1.22a2.2,2.2,0,0,0-1.54-1.53l-.14,0v7.51l2.74-1.16L274.5,372v-7.52Z"
          />
        </g>
        <g className="cls-9">
          <path
            className="cls-15"
            d="M314,364.53l29.31,16.92c.41.23.73.1.73-.31a1.52,1.52,0,0,0-.73-1.16L314,363.06c-.4-.23-.73-.1-.73.31A1.52,1.52,0,0,0,314,364.53Z"
          />
          <path
            className="cls-15"
            d="M334.52,405.69l-29.31-16.92c-.4-.23-.73-.1-.73.31a1.52,1.52,0,0,0,.73,1.16l29.31,16.92c.4.23.73.1.73-.31A1.52,1.52,0,0,0,334.52,405.69Z"
          />
          <path
            className="cls-15"
            d="M343.32,395.37l-13.92-8c-.4-.23-.73-.1-.73.31a1.52,1.52,0,0,0,.73,1.16l13.92,8c.41.23.73.1.73-.31A1.52,1.52,0,0,0,343.32,395.37Z"
          />
          <path
            className="cls-15"
            d="M305.21,374.84l14.66,8.46c.4.23.73.1.73-.31a1.52,1.52,0,0,0-.73-1.16l-14.66-8.46c-.4-.23-.73-.1-.73.31A1.52,1.52,0,0,0,305.21,374.84Z"
          />
          <path
            className="cls-23"
            d="M309.61,356.86c2.43,1.4,4.4,4.51,4.4,6.94s-2,3.26-4.4,1.86-4.4-4.51-4.4-6.94S307.18,355.46,309.61,356.86Z"
          />
          <path
            className="cls-23"
            d="M324.42,380.49c2.51,1.45,4.55,4.66,4.55,7.17s-2,3.37-4.55,1.92-4.55-4.66-4.55-7.17S321.9,379,324.42,380.49Z"
          />
          <path
            className="cls-23"
            d="M338.92,404.56c2.43,1.4,4.4,4.51,4.4,6.94s-2,3.26-4.4,1.86-4.4-4.51-4.4-6.94S336.49,403.16,338.92,404.56Z"
          />
          <path
            className="cls-10"
            d="M309.61,355.39c2.43,1.4,4.4,4.51,4.4,6.94s-2,3.26-4.4,1.86-4.4-4.51-4.4-6.94S307.18,354,309.61,355.39Z"
          />
          <path
            className="cls-10"
            d="M324.42,379c2.51,1.45,4.55,4.66,4.55,7.17s-2,3.37-4.55,1.92-4.55-4.66-4.55-7.17S321.9,377.58,324.42,379Z"
          />
          <path
            className="cls-10"
            d="M338.92,403.1c2.43,1.4,4.4,4.51,4.4,6.94s-2,3.26-4.4,1.86-4.4-4.51-4.4-6.94S336.49,401.69,338.92,403.1Z"
          />
        </g>
      </GLayer>
    </Svg>
  );
});

export default IntroIllustration;
