import * as React from 'react';
import styled, { css } from 'styled-components';
import device from '@helpers/styled-breakpoints';
import InputMask from 'react-input-mask';

const Wrap = styled.div`
  width: 100%;
  margin-bottom: 20px;
`;

const Error = styled.div`
  font-family: Geometria, sans-serif;
  font-size: 12px;
  color: #e60003;
  margin-top: 4px;
`;

interface IInputStyled extends React.InputHTMLAttributes<any> {
}

const InputStyled = styled(InputMask)<IInputStyled>`
  outline: none;
  border: none;
  width: 100%;
  font-family: Geometria, sans-serif;
  padding-bottom: 5px;
  transition: border 200ms ease-out;
`;

const mapInputThemes = {
  primary: css`
    border-bottom: 2px solid #C5C6C8;

    &:focus, &:hover {
      border-bottom: 2px solid #8464fe;;
    }
  `,
  primaryError: css`
    border-bottom: 2px solid #ff4a4a;

    &:focus, &:hover {
      border-bottom: 2px solid #ff4a4a;
    }
  `,
};

interface IPropsInnerWrap {
  isError: boolean;
}

const InnerWrap = styled.div<IPropsInnerWrap>`
  width: 100%;
  position: relative;
  padding-top: 20px;
  
  ${InputStyled} {
    ${({ isError }) => (isError ? mapInputThemes.primaryError : mapInputThemes.primary)}    
  }
`;


interface IPropsLabel {
  raised: boolean;
}

const Label = styled.span.attrs<IPropsLabel>(({ raised }) => ({
  style: {
    transform: `${raised ? 'translateY(-100%) translateX(-1px) scale(0.8)' : ''}`,
  },
}))<IPropsLabel>`
  position: absolute;
  user-select: none;
  cursor: text;
  transform-origin: top left;
  color: #8464FE;
  font-family: Geometria, sans-serif;
  bottom: 7px;
  transition: 200ms ease-out transform;
`;

interface IProps extends React.InputHTMLAttributes<any> {
  label: React.ReactNode;
  value?: string;
  type?: string;
  mask?: string;
  error?: React.ReactNode;
  isSubmitted: boolean;
}

const Input = React.memo(({
  label,
  value = '',
  type = 'text',
  onChange,
  mask,
  error,
  isSubmitted = false,
}: IProps) => {
  const [isFocus, setIsFocus] = React.useState<boolean>(false);
  const [isBlurred, setIsBlurred] = React.useState<boolean>(false);

  const inputRef = React.useRef<HTMLInputElement>();

  const createInputRef = React.useCallback((input) => {
    inputRef.current = input;
  }, []);

  const onClickLabel = () => {
    setIsFocus(true);
    inputRef.current.focus();
  };

  const onBlur = () => {
    setIsFocus(false);
    setIsBlurred(true);
  };

  const onFocus = () => {
    setIsFocus(true);
  };

  const raisedLabel = Boolean(value || isFocus);
  const isShowError = Boolean((isBlurred || isSubmitted) && error);

  return (
    <Wrap>
      <InnerWrap
        isError={isShowError}
      >
        <Label onClick={onClickLabel} raised={raisedLabel}>{label}</Label>
        <InputStyled
          onBlur={onBlur}
          onFocus={onFocus}
          onChange={onChange}
          value={value}
          type={type}
          mask={mask}
          maskChar=""
          inputRef={createInputRef}
        />
      </InnerWrap>
      {isShowError ? <Error>{error}</Error> : null}
    </Wrap>
  );
});

export default Input;
