import * as React from 'react';
import styled, {css} from 'styled-components';
import Tooltip from '@components/elements/tooltip';
import debounce from 'lodash.debounce';
import * as copy from 'copy-to-clipboard';

const mapThemesWrap = {
  primary: css`
    background-color: #8464FE;
    padding: 15px 0;
    text-align: center;
    font-family: Geometria, sans-serif;
    font-weight: 700;
    font-size: 24px;
    color: white;
    letter-spacing: 1px;
    transition: background ease .3s;
    cursor: pointer;
    margin-top: 20px;
    
    &:hover {
      background-color: #6c4bed;    
    }    
  `,
  transparent: css``,
};

type Theme = keyof typeof mapThemesWrap;

interface IPropsWrap {
  theme: Theme;
  fixMargin?: boolean;
}

const Wrap = styled.div<IPropsWrap>`
  position: relative;
  margin: ${({fixMargin}) => fixMargin ? '0 -20px' : 0};
  ${({theme}) => mapThemesWrap[theme]};
  
  @media (min-width: 576px) {
     margin: ${({fixMargin}) => fixMargin ? '0 -40px' : 0};  
  }
`;

interface IProps {
  theme?: Theme;
  children: React.ReactNode;
  textForCopy: string;
  fixMargin?: boolean;
}

const WrapWithCopyByClick = React.memo(({
  theme = 'primary',
  textForCopy,
  children,
  fixMargin,
}: IProps) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const debouncedCloseTooltip = React.useRef(debounce(() => {
    setShowTooltip(false);
  }, 2000)).current;

  const onClick = () => {
    copy(textForCopy);
    setShowTooltip(true);
    debouncedCloseTooltip();
  };

  React.useEffect(() => {
    return () => {
      debouncedCloseTooltip.cancel();
    };
  }, []);

  return (
    <Wrap fixMargin={fixMargin} theme={theme} onClick={onClick}>
      <Tooltip translateY="-44px" show={showTooltip}>
        Скопировано в буфер обмена
      </Tooltip>
      {children}
    </Wrap>
  );
});

export default WrapWithCopyByClick;
