import * as React from "react";

function LogoMiniBright(props) {
  return (
    <svg viewBox="0 0 205 62" fill="none" {...props}>
      <path d="M69.25 51.13V8.07" stroke="#fff" strokeWidth={0.75} />
      <path
        d="M85.08 38.45H81.9L76 20.85h4l3.6 11.8 4-11.85h2.8l4 11.85 3.6-11.8h3.92L96 38.45h-3.2L89 26.78l-3.92 11.67zM117.42 38.35H104.3v-17.5h12.85v3.23h-9.25v3.38h7.45v3.17h-7.45v4.5h9.52v3.22zM129.1 38.35h-8.8v-17.5h8.23a6 6 0 013.86 1.15 3.893 3.893 0 011.44 3.23 3.723 3.723 0 01-.62 2.17 3.586 3.586 0 01-1.58 1.3 4.48 4.48 0 012.5 1.55c.705.886 1.061 2 1 3.13a4.454 4.454 0 01-1.65 3.6 6.629 6.629 0 01-4.38 1.37zm-5.2-14.5v3.85h4.1a2.79 2.79 0 001.76-.5 1.682 1.682 0 00.64-1.4 1.732 1.732 0 00-.64-1.44 2.74 2.74 0 00-1.76-.51h-4.1zm0 6.83v4.57h4.52a3.33 3.33 0 002.1-.59 2.002 2.002 0 00.75-1.69 2.05 2.05 0 00-.75-1.7 3.28 3.28 0 00-2.1-.6l-4.52.01zM150.37 38.65a12.289 12.289 0 01-3.62-.54 8.598 8.598 0 01-2.75-1.33v-1.33a9.255 9.255 0 002.67 1.48c1.161.43 2.392.643 3.63.63 1.317.06 2.62-.29 3.73-1a3.196 3.196 0 001.48-2.75 3.098 3.098 0 00-1-2.32 4.484 4.484 0 00-1.2-.73 10.23 10.23 0 00-1.4-.55c-.233-.087-.783-.247-1.65-.48l-1.87-.55c-.6-.233-1.11-.45-1.53-.65a4.26 4.26 0 01-1.7-1.41 4.49 4.49 0 01-.6-2.21A3.795 3.795 0 01146 21.8a6.63 6.63 0 014.27-1.25 9.53 9.53 0 015.3 1.47v1.23a9.2 9.2 0 00-5.25-1.6 5.73 5.73 0 00-3.48.91 2.839 2.839 0 00.17 4.79c.317.191.652.352 1 .48.353.146.713.273 1.08.38l1.2.35.53.15 1.7.52a8.685 8.685 0 011.52.65c.509.216.977.52 1.38.9a4.068 4.068 0 011.2 3 4.17 4.17 0 01-1.83 3.6 7.649 7.649 0 01-4.42 1.27zM166.17 38.35H165V21.93h-6.27v-1.08h13.7v1.08h-6.27l.01 16.42zM172.9 38.35h-1.22l7.95-17.6h1.08l7.9 17.6h-1.2l-2.32-5.15h-9.85l-2.34 5.15zm7.25-16.1l-4.52 10h9l-4.48-10zM193.2 38.35H192v-17.5h7.28a5.76 5.76 0 014 1.27 4.818 4.818 0 011.438 3.43 4.807 4.807 0 01-1.438 3.43 5.73 5.73 0 01-4 1.29h-6.08v8.08zm0-16.42v7.27h6a4.869 4.869 0 003.21-1 3.745 3.745 0 001.136-2.685 3.743 3.743 0 00-1.136-2.685 4.88 4.88 0 00-3.21-.95l-6 .05z"
        fill="#fff"
      />
      <path
        d="M30.3.73l12.71 7.34-29.37 16.96L.93 17.69 30.3.73z"
        fill="#FACCFF"
      />
      <path
        d="M43 22.78l12.72 7.35-29.37 16.95-12.72-7.34L43 22.78z"
        fill="#F7A9FF"
      />
      <path
        d="M13.64 25.03v14.69l29.37-16.96V8.07L13.64 25.03z"
        fill="url(#prefix__paint0_linear)"
      />
      <path
        d="M26.35 47.09v14.68l29.37-16.96V30.13L26.35 47.09z"
        fill="url(#prefix__paint1_linear)"
      />
      <path
        d="M57.32 54.34v-1.5"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path
        d="M57.13 49.64C55.59 34.8 45.3 30.58 39.82 31.45a6.16 6.16 0 00-2.29.88"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeDasharray="3.2 3.2"
      />
      <path
        d="M36.25 33.29c-.371.335-.722.693-1.05 1.07"
        stroke="#fff"
        strokeWidth={1.5}
        strokeLinecap="round"
      />
      <path d="M35.19 34.5V33" stroke="#fff" strokeLinecap="round" />
      <path
        d="M35.1 30.09C33.84 9.22 20.59 2.43 12.5 2.71"
        stroke="#fff"
        strokeLinecap="round"
        strokeDasharray="2.91 2.91"
      />
      <path
        d="M11.06 2.84H11a7.838 7.838 0 00-1.39.36"
        stroke="#fff"
        strokeLinecap="round"
      />
      <path d="M9.74.93L4.61 6.88l7.42-2.59L9.74.93z" fill="#fff" />
      <path
        d="M.93 17.69v29.39l25.42 14.69V47.09l-12.71-7.37V25.03L.93 17.69z"
        fill="#7132BA"
      />
      <defs>
        <linearGradient
          id="prefix__paint0_linear"
          x1={13.64}
          y1={23.89}
          x2={43.01}
          y2={23.89}
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#9E4FC9" />
          <stop offset={1} stopColor="#6129DD" />
        </linearGradient>
        <linearGradient
          id="prefix__paint1_linear"
          x1={26.35}
          y1={45.95}
          x2={55.72}
          y2={45.95}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0.2} stopColor="#692D8A" />
          <stop offset={1} stopColor="#552191" />
        </linearGradient>
      </defs>
    </svg>
  );
}

const MemoLogoMiniBright = React.memo(LogoMiniBright);
export default MemoLogoMiniBright;
