import * as React from "react";

function OnlineStorefront(props) {
  return (
    <svg viewBox="0 0 89 89" fill="none" {...props}>
      <path
        d="M87.696 10.355H42.43a1.304 1.304 0 100 2.608h43.964v9.895H2.607v-9.895h33.881a1.304 1.304 0 100-2.608H1.304C.584 10.355 0 10.94 0 11.66V77.34c0 .72.584 1.304 1.304 1.304h54.305a1.304 1.304 0 100-2.608H2.607v-4.854h83.786v4.854H61.55a1.304 1.304 0 100 2.608h26.145c.72 0 1.304-.584 1.304-1.304V11.659c0-.72-.584-1.304-1.304-1.304zm-1.303 58.22H2.607v-43.11h83.786v43.11z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M8.931 16.607h-.018a1.304 1.304 0 100 2.607h.018a1.304 1.304 0 100-2.607zM13.768 16.607h-.019a1.304 1.304 0 100 2.607h.019a1.304 1.304 0 100-2.607zM18.604 16.607h-.018a1.304 1.304 0 100 2.607h.018a1.304 1.304 0 100-2.607zM80.775 16.607H66.242a1.304 1.304 0 100 2.607h14.533a1.304 1.304 0 100-2.607zM61.575 16.607h-3.2a1.304 1.304 0 100 2.607h3.2a1.304 1.304 0 100-2.607zM74.881 42.802h-6.03a1.304 1.304 0 100 2.608h4.726v9.186H16.982V45.41H62.91a1.304 1.304 0 100-2.608H15.678c-.72 0-1.304.584-1.304 1.304V55.9c0 .72.584 1.303 1.304 1.303h59.203c.72 0 1.304-.583 1.304-1.303V44.106c0-.72-.584-1.304-1.304-1.304z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M26.127 47.421a3.07 3.07 0 00-2.185-.905 3.07 3.07 0 00-2.185.905 3.092 3.092 0 00-.715 3.242l-.733.732a1.304 1.304 0 101.844 1.844l.731-.731a3.086 3.086 0 003.243-.716 3.094 3.094 0 000-4.37zm-1.844 2.527a.48.48 0 01-.683 0 .483.483 0 11.683 0zM41.601 59.446H27.424a1.304 1.304 0 100 2.607h14.177a1.304 1.304 0 100-2.607zM61.575 59.446H47.4a1.304 1.304 0 100 2.607h14.176a1.304 1.304 0 100-2.607zM56.968 31.55H32.032c-.72 0-1.304.584-1.304 1.304v6.084c0 .72.584 1.303 1.304 1.303h24.936c.72 0 1.304-.583 1.304-1.303v-6.084c0-.72-.584-1.304-1.304-1.304zm-1.304 6.084H33.336v-3.477h22.328v3.477z"
        fill="#232323"
        fillOpacity={0.86}
      />
    </svg>
  );
}

const MemoOnlineStorefront = React.memo(OnlineStorefront);
export default MemoOnlineStorefront;
