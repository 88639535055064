/* eslint-disable max-len */
import * as React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import {graphql, useStaticQuery} from 'gatsby';
import device from '@helpers/styled-breakpoints';
import {createCalcScale3d} from '@utils/math-addition';
import ButtonShowMore from '@components/elements/button-show-more';
import {
  Tilt,
  PortfolioItemTitle,
  BackgroundImgWrap,
  Background2ImgWrap,
  WrapContent,
  StyledImageBackground,
} from '@components/blocks/portfolio-items/elements';

const calcScale = createCalcScale3d(500, true);

const LilubagsBreakLineWrap = styled.span`
  margin-left: 12px;
`;

const LilubagsFilter = styled.div`
  display: none;
  align-items: center;
  justify-content: center;
  height: 55px;
  width: 55px;
  background-color: #9481F8;
  color: white;
  box-shadow: 0 3px 5px 1px rgba(0,0,0,0.1);
  position: absolute;
  border-radius: 50%;
  font-family: Geometria, sans-serif;
  font-size: 12px;
  right: 35px;
  top: 35px;
  
  @media ${device.mobil} {
    display: flex;
    height: 55px;
    width: 55px;
  }
  
  @media ${device.tablet} {
    height: 70px;
    width: 70px;
  }
  
  @media ${device.desktop} {
    transform: ${calcScale(150)};
    right: 125px;
    top: 85px;
    font-size: 14px;
    height: 75px;
    width: 75px;
  }
  
  @media ${device.extra} {
  }
`;

const WrapImage = styled.div`
  position: absolute;
  top: -33px;
  right: -20px;
  z-index: 5;
  display: none;
  
  @media ${device.mobil} {
    display: block;
    top: -33px;
  }
  
  @media ${device.tablet} {
    top: -50px;
  }
  
  @media ${device.desktop} {
    transform: ${calcScale(150)} translateX(-50px) translateY(25px);
    top: auto;
    right: -20px;
    bottom: 0;
  }
  
  @media ${device.extra} {
  }
`;

const StyledImg = styled(Img)`
  width: 250px;
  
  @media ${device.mobil} {
    width: 250px;
  }
  
  @media ${device.tablet} {
    width: 320px;
  }
  
  @media ${device.desktop} {
    width: 379px;
  }
  
  @media ${device.extra} {
  }
`;

const LilubagsUIExampleMenu = styled.ul`
  list-style: none;
  padding: 0 0 8px 0;
  border-bottom: 1px solid rgba(255,255,255,0.7);
  display: inline-flex;
  margin-left: 30px;
`;

const LilubagsUIExampleItem = styled.li`
  padding: 0 6px;
  font-family: Geometria, sans-serif;
  color: rgba(0,0,0,0.75);
  font-weight: 500;
  font-size: 11px;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    font-size: 14px;
  }
  
  @media ${device.extra} {
  }
`;

const LilubagsUIExampleButtons = styled.div`
  box-shadow: 0 2px 10px 1px rgba(0,0,0,0.25);
  display: inline-flex;
  position: relative;
  z-index: 10;
  margin-left: 30px;
`;

const LilubagsUIExampleButton = styled.div`
  padding: 8px 12px;
  font-family: Geometria, sans-serif;
  color: rgba(0,0,0,0.75);
  font-weight: 500;
  font-size: 12px;
  background-color: #FFFFFF;
  display: flex;
  align-items: center;
  
  &:not(:nth-child(1)) {
    border-left: 1px solid #dfdfdf;
  }
  
  svg {
    margin-right: 8px;
  }
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    padding: 14px 22px;
    font-size: 14px;
  }
  
  @media ${device.extra} {
  }
`;

const LilubagsLogo = styled.div`
  margin: 24px 0 10px 30px;
  position: relative;
  z-index: 10;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
    margin: 90px 0 10px 30px;
  }
  
  @media ${device.desktop} {
    margin: 24px 0 10px 30px;
  }
  
  @media ${device.extra} {
  }
`;

interface IProps {
}

const Lilubags = React.memo(({}: IProps) => {
  const data = useStaticQuery(graphql`
    query {
      girl: file(relativePath: { eq: "portfolio/lilubags/girl.png" }) {
        childImageSharp {
          fluid(maxHeight: 379, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      background: file(relativePath: { eq: "portfolio/lilubags/lilubags-background.png" }) {
        childImageSharp {
          fluid(maxHeight: 420, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      background2: file(relativePath: { eq: "portfolio/lilubags/lilubags-background-layer-2.png" }) {
        childImageSharp {
          fluid(maxHeight: 420, quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Tilt>
      <BackgroundImgWrap>
        <StyledImageBackground fluid={data.background.childImageSharp.fluid} />
      </BackgroundImgWrap>
      <Background2ImgWrap>
        <StyledImageBackground fluid={data.background2.childImageSharp.fluid} />
      </Background2ImgWrap>
      <WrapImage>
        <StyledImg fluid={data.girl.childImageSharp.fluid} />
      </WrapImage>
      <WrapContent>
        <PortfolioItemTitle>
          Интернет-магазин <br /> LILU BAGS
          <LilubagsBreakLineWrap>
            <svg width="69" height="20" viewBox="0 0 69 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 2.5L14 17.5L27.5 2.5L40.5 17.5L53.5 2.5L67.5 17.5" stroke="#EDE2C8" strokeWidth="2" />
            </svg>
          </LilubagsBreakLineWrap>
        </PortfolioItemTitle>

        <LilubagsUIExampleMenu>
          <LilubagsUIExampleItem>
            Рюкзаки
          </LilubagsUIExampleItem>
          <LilubagsUIExampleItem>
            Сумки
          </LilubagsUIExampleItem>
          <LilubagsUIExampleItem>
            Сумочки
          </LilubagsUIExampleItem>
          <LilubagsUIExampleItem>
            Кошельки
          </LilubagsUIExampleItem>
        </LilubagsUIExampleMenu>

        <br />

        <LilubagsUIExampleButtons>
          <LilubagsUIExampleButton>
            Фильтр
          </LilubagsUIExampleButton>
          <LilubagsUIExampleButton>
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
              <g fill="none" fillRule="evenodd" opacity=".5">
                <circle cx="10" cy="10" r="10" fill="#313D53" fillRule="nonzero" />
                <path
                  fill="#FFF"
                  d="M10.905 10l2.926 2.99a.6.6 0 0 1 0 .837.57.57 0 0 1-.818 0L10 10.747l-3.013 3.08a.57.57 0 0 1-.817 0 .6.6 0 0 1 0-.836L9.095 10 6.17 7.01a.6.6 0 0 1 0-.837.57.57 0 0 1 .817 0L10 9.253l3.013-3.08a.57.57 0 0 1 .818 0 .6.6 0 0 1 0 .836L10.905 10z"
                />
              </g>
            </svg>
            Сбросить фильтр
          </LilubagsUIExampleButton>
        </LilubagsUIExampleButtons>

        <LilubagsLogo>
          <svg xmlns="http://www.w3.org/2000/svg" width="110" height="53" viewBox="0 0 92 44">
            <g fill="none" fillRule="evenodd">
              <text
                fill="#FFFFFF"
                fontFamily="AbrilFatface-Regular, Abril Fatface"
                fontSize="23.467"
                fontWeight="700"
              >
                <tspan x="0" y="29.693">Lilubags</tspan>
              </text>
              <path fill="#FFF" d="M0 41.067h42.24v2.347H0z" />
              <path fill="#FFF" d="M42.24 42.24h49.28v1.173H42.24z" opacity=".75" />
              <g>
                <path fill="#FFF" d="M49.28 0h42.24v2.347H49.28z" />
                <path fill="#FFF" d="M0 0h49.28v1.173H0z" opacity=".75" />
              </g>
            </g>
          </svg>
        </LilubagsLogo>

        <ButtonShowMore left="68px" bottom="50px" href="http://lilubags.webstap.ru/" />
      </WrapContent>
      <LilubagsFilter>
        Фильтр
      </LilubagsFilter>
    </Tilt>
  );
});

export default Lilubags;
