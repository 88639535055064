import * as React from 'react';
import styled, {css, CSSProp} from 'styled-components';
import device from '@helpers/styled-breakpoints';
import {Transition} from 'react-transition-group';

const wrapStates = {
  entering: css`
  top: -10px;
  opacity: 0;  
`,
  entered: css`
  top: 0;
  opacity: 1; 
`,
  exiting: css`
  top: -10px;
  opacity: 0; 
`,
  exited: css`
  top: 0;
  opacity: 1; 
`,
};

const Arrow = styled.span`
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-50%) translateY(100%);
  bottom: 0;
  z-index: 5;
  border-style: solid;
  border-color: black transparent transparent transparent;
  border-width: 8px 8px 0 8px;
`;


const Content = styled.div`
  position: relative;
  font-family: Geometria, sans-serif;
  font-weight: 500;
  font-size: 14px;
  white-space: nowrap;
  background-color: black;
  color: white;
  border-radius: 4px;
  padding: 10px 20px;
  z-index: 10;
`;

interface IPropsWrap {
  cssProp: CSSProp;
  state: string;
  translateY: string;
}

const Wrap = styled.div<IPropsWrap>`
  position: absolute;
  transition: top 300ms ease-out, opacity 300ms ease-out;
  left: 50%;
  transform: translateX(-50%) translateY(${({translateY}) => translateY});
  
  ${({cssProp}) => cssProp}
  ${({state}) => wrapStates[state]}
`;

interface IProps {
  css?: CSSProp;
  children: React.ReactNode;
  show: boolean;
  translateY: string;
}

const Tooltip = React.memo(({
  css: cssProp,
  show,
  children,
  translateY,
}: IProps) => {

  return (
    <Transition in={show} timeout={{ enter: 1, appear: 300, exit: 300 }} unmountOnExit>
      {(state) => (
        <Wrap
          cssProp={cssProp}
          state={state}
          translateY={translateY}
        >
          <Arrow />
          <Content>
            {children}
          </Content>
        </Wrap>
      )}
    </Transition>
  );
});

export default Tooltip;
