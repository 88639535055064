import * as React from 'react';
import styled from 'styled-components';
import device from '@helpers/styled-breakpoints';
import { createScrollDirectionHandler } from '@utils/dom-utils';
import NavButton from '@components/elements/nav-button';

const { useState, useEffect } = React;

const Wrap = styled.div`
  display: block;
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    display: none;
  }
  
  @media ${device.extra} {
  }
`;

const Nav = styled.nav`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(-100%);
  box-shadow: 0 0 20px 2px transparent;
  transition: 200ms ease-out transform, 200ms ease-out box-shadow;

  .isOpen & {
    top: 0;
    transform: translateY(0);
    box-shadow: 0 0 20px 2px black;
  }
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    display: none;
  }
  
  @media ${device.extra} {
  }
`;

const List = styled.ul`
  display: flex;
  flex-direction: column;
  list-style: none;
  margin: 0;
  font-family: 'Roboto', sans-serif;
  padding: 20px 0;
  background: linear-gradient(#4E1B64 0%, #281664 100%);
 
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    display: none;
  }
  
  @media ${device.extra} {
  }
`;

interface IProps {
  children: React.ReactNode;
}

const NavbarMobil = ({ children }: IProps) => {
  const [isOpen, toggleOpen] = useState(false);
  const [isShowButton, toggleShowButton] = useState(true);

  const onClickToggleMenu = () => {
    toggleOpen(!isOpen);
  };

  useEffect(() => {
    const scrollHandlerUp = () => {
      toggleShowButton(true);
    };

    const scrollHandlerDown = () => {
      toggleShowButton(false);
      toggleOpen(false);
    };

    const scrollHandler = createScrollDirectionHandler(scrollHandlerUp, scrollHandlerDown, 200);

    window.addEventListener('scroll', scrollHandler);
    return () => {
      window.removeEventListener('scroll', scrollHandler);
    };
  }, []);

  return (
    <Wrap className={isOpen ? 'isOpen' : ''}>
      <Nav>
        <List>
          {children}
        </List>
      </Nav>

      <NavButton visible={isShowButton} isOpenNavbar={isOpen} onClick={onClickToggleMenu} />
    </Wrap>
  );
};

export default NavbarMobil;
