import * as React from 'react';
import styled, { css } from 'styled-components';
import device from '@helpers/styled-breakpoints';
import { Transition } from 'react-transition-group';

const buttonStates = {
  entering: css`
  top: -48px;  
`,
  entered: css`
  top: 20px;
`,
  exiting: css`
  top: -48px;
`,
  exited: css`
  top: -48px;
`,
};

const Button = styled.button<{ state: string, isOpenNavbar: boolean }>`
  position: fixed;
  z-index: 9999;
  right: 20px;
  display: block;
  height: 48px;
  width: 48px;
  padding: 10px;
  border: none;
  border-radius: 6px;
  background-color: ${({ isOpenNavbar }) => (isOpenNavbar ? 'rgba(84,28,100,0.5)' : '#541C64')};
  transition: 300ms ease-out background-color, 300ms ease-out top;
  
  ${({ state }) => buttonStates[state]}
  
  &:active {
    outline: none;
  }
  
  @media ${device.mobil} {
  }
  
  @media ${device.tablet} {
  }
  
  @media ${device.desktop} {
    display: none;
  }
  
  @media ${device.extra} {
  }
`;

const lineCSS = css`
  &:nth-child(1) {
    top: 50%;
    transform: translateX(-50%) translateY(-50%) rotateZ(45deg);
  }
  
  &:nth-child(2) {
    transform: translateX(-50%) translateY(-50%) rotateZ(-45deg);
  }
  
  &:nth-child(3) {
    display: none;
  }
`;

const Line = styled.span<{ top: string, isOpenNavbar: boolean }>`
  position: absolute;
  top: ${({ top }) => top};
  background-color: white;
  height: 3px;
  border-radius: 4px;
  width: 36px;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
  transition: 200ms ease-out transform, 200ms ease-out top;
  transform-origin: center;
  
  ${({ isOpenNavbar }) => (isOpenNavbar ? lineCSS : '')}
`;

interface IProps {
  visible: boolean;
  isOpenNavbar: boolean;
  onClick: (e?: React.MouseEvent<HTMLButtonElement>) => void;
}

const NavButton = ({
  visible,
  isOpenNavbar,
  onClick,
}: IProps) => {

  return (
    <Transition in={visible} timeout={{ enter: 1, appear: 300, exit: 300 }} unmountOnExit>
      {(state) => (
        <Button isOpenNavbar={isOpenNavbar} state={state} type="button" onClick={onClick}>
          <Line isOpenNavbar={isOpenNavbar} top="25%" />
          <Line isOpenNavbar={isOpenNavbar} top="50%" />
          <Line isOpenNavbar={isOpenNavbar} top="75%" />
        </Button>
      )}
    </Transition>
  );
};

export default NavButton;
