import * as React from 'react';
import styled from 'styled-components';
import { IconArrow, MoreInfo } from '@components/elements/order-elements';
import { mapOrderRates, mapOrderTypeTitles, Order } from './map-order-rates';

const Wrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border-radius: 30px;
  padding: 30px 25px;
  cursor: pointer;
  color: #521C64;
  box-shadow: 5px 5px 14px rgba(0, 0, 0, 0.16);
  transition: box-shadow 200ms ease-out, transform 150ms ease-out;
  
  &:hover {
    box-shadow: 5px 5px 20px rgba(0,0,0,0.51);
    transform: scale(1.025);    
  }
`;

const Title = styled.div`
  font-family: Geometria, sans-serif;
  font-weight: bold;
  font-size: 30px;
`;

const List = styled.ul`
  list-style: none;
  padding: 0 0 20px 0;
  margin: 20px 0 0 0;
  position: relative;
  
  &:before {
    content: '';
    position: absolute;
    bottom: 0;
    height: 2px;
    width: 80px;
    background-color: #551C64;
  }
`;

const ListItem = styled.li`
  font-family: Geometria, sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  padding-left: 20px;
  position: relative;
  margin-top: 10px;
  
  strong {
    color: #521C64;
  }
  
  &:before {
    content: '';
    position: absolute;
    border-radius: 50%;
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    height: 8px;
    width: 8px;
    background: linear-gradient(180deg, #551C64 0%, #1B1464 100%);
  }
`;

const Cost = styled.div`
  font-family: Geometria, sans-serif;
  font-weight: bold;
  font-size: 34px;
  margin-top: 40px;
  
  span {
    font-family: Geometria, sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    margin-right: 10px
  }
`;

interface IProps {
  orderType: 'lite' | 'standard' | 'premium';
  orderSection: Order | '';

  onClick(e: any): void;
}

const OrderType = React.memo(({
  orderType,
  orderSection,
  onClick,
}: IProps) => {

  if (!orderSection) {
    return null;
  }


  return (
    <Wrap onClick={onClick}>
      <div>
        <Title>
          {mapOrderTypeTitles[orderType]}
        </Title>
        <List>
          {(mapOrderRates[orderSection][orderType].properties as Array<any>).map((el) => (
            <ListItem key={el}>{el}</ListItem>
          ))}
        </List>
      </div>

      <div>
        <Cost>
          <span>от</span>
          {mapOrderRates[orderSection][orderType].cost}₽
        </Cost>
        <MoreInfo>
          <span>заказать</span>
          <IconArrow />
        </MoreInfo>
      </div>
    </Wrap>
  );
});

export default OrderType;
