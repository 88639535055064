import * as React from 'react';
import styled from 'styled-components';
import ReactWave from 'react-wavify';

const Wrap = styled.div<{ top: string }>`
  position: absolute;
  left: 0;
  right: 0;
  top: ${({ top }) => top || 0};
  z-index: 50;
  opacity: 1;
  transform: rotateX(180deg);
`;

const SWave = styled(ReactWave)`
  height: 1450px;
  width: 200vh;
  opacity: ${({ opactiy }) => opactiy}
`;

interface IProps {
  height: number;
  amplitude: number;
  points: number;
  speed: number;
  cssTop: string;
  fill: string;
  opacity: string | number;
  children?: React.ReactNode;
  paused?: boolean;
}

const Wave = React.memo(({
  opacity,
  fill,
  cssTop,
  children,
  paused = false,
  ...options
}: IProps) => {

  return (
    <Wrap top={cssTop}>
      <SWave paused={paused} opacity={opacity} options={options} fill={fill}>
        {children}
      </SWave>
    </Wrap>
  );
});

export default Wave;
