import * as React from "react";

function Blog(props) {
  return (
    <svg viewBox="0 0 70 70" fill="none" {...props}>
      <g clipPath="url(#prefix__clip0)" fill="#232323" fillOpacity={0.86}>
        <path d="M7.683 6.43h-.027a1.033 1.033 0 100 2.066h.027a1.033 1.033 0 100-2.067zM12.34 6.43h-.027a1.033 1.033 0 000 2.066h.027a1.033 1.033 0 100-2.067zM16.994 6.453h-.027a1.034 1.034 0 000 2.066h.027a1.033 1.033 0 100-2.066zM25.586 8.785h18.129a1.033 1.033 0 100-2.066h-18.13a1.033 1.033 0 100 2.066zM13.005 37.865h15.171a1.033 1.033 0 100-2.067h-15.17a1.25 1.25 0 01-1.248-1.248v-1.078l10.247-4.511c2.408-1.06 2.865-.756 5.064.698.255.169.526.35.82.54l.546.353c2.833 1.847 3.387 1.988 6.663.378l4.16-2.042c2.297-1.131 2.878-.784 5.036.505.426.255.91.545 1.463.851l3.003 1.67v2.636a1.25 1.25 0 01-1.248 1.248H33.076a1.034 1.034 0 000 2.067h14.436a3.318 3.318 0 003.314-3.315V21.908a3.319 3.319 0 00-3.314-3.315H13.005a3.319 3.319 0 00-3.314 3.315V34.55a3.318 3.318 0 003.314 3.315zm-1.247-15.957c0-.689.56-1.248 1.247-1.248h34.507c.677 0 1.248.571 1.248 1.248v7.642l-1.998-1.11a50.664 50.664 0 01-1.408-.82c-2.31-1.38-3.704-2.213-7.007-.586l-4.16 2.042c-1.325.65-1.992.938-2.439.903-.458-.032-1.072-.432-2.186-1.157a56.405 56.405 0 00-.55-.358c-.289-.188-.554-.364-.803-.528-2.494-1.65-3.735-2.318-7.036-.865l-9.415 4.142v-9.305z" />
        <path d="M66.592 13.368a5.397 5.397 0 00-4.81.427V5.67a3.624 3.624 0 00-3.621-3.62H3.621A3.624 3.624 0 000 5.67v58.66a3.624 3.624 0 003.62 3.62h54.541a3.624 3.624 0 003.62-3.62V40.137l7.236-18.25c.002-.003.005-.007.006-.012l.6-1.509a5.403 5.403 0 00-3.031-6.998zm-4.81 3.264a3.333 3.333 0 014.05-1.342 3.332 3.332 0 011.87 4.315l-.218.548-5.702-2.258-.481-.19.215-.546c.074-.186.163-.363.266-.527zM59.715 64.33c0 .856-.697 1.553-1.554 1.553H3.621a1.556 1.556 0 01-1.554-1.553V13.457h15.436a1.033 1.033 0 000-2.067H2.067V5.67c0-.855.697-1.553 1.554-1.553h54.54c.857 0 1.554.698 1.554 1.553v5.72h-37.31a1.033 1.033 0 000 2.067h37.31v2.666c-.043.09-.082.181-.119.274l-.583 1.47v.003a.28.28 0 00-.016.034l-9.787 24.69H16.94a1.033 1.033 0 000 2.067h31.45l-1.192 3.008H10.724a1.033 1.033 0 000 2.067h35.941l-.081 3.004h-35.86a1.033 1.033 0 000 2.067h35.804l-.061 2.256v.035c0 .25.029.488.08.718H10.723a1.033 1.033 0 000 2.066h37.668c.253.077.511.117.77.117.793 0 1.583-.354 2.171-1.009l5.256-5.923c.084-.094.146-.2.189-.31l2.937-7.408v18.98zm-11.1-10.219l.093-3.44 5.36 2.123-2.284 2.573-3.17-1.256zm1.71 2.901l-.534.602c-.294.328-.622.36-.83.281-.266-.1-.422-.39-.428-.795l.021-.79 1.771.702zm11.457-22.48l-2.067 5.211-4.478 11.293-6.18-2.448 10.658-26.886.823-2.077 1.244.493 4.94 1.956-4.94 12.457z" />
      </g>
      <defs>
        <clipPath id="prefix__clip0">
          <path fill="#fff" d="M0 0h70v70H0z" />
        </clipPath>
      </defs>
    </svg>
  );
}

const MemoBlog = React.memo(Blog);
export default MemoBlog;
