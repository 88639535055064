import * as React from 'react';
import MainLayout from '@components/layouts/main-layout';
import SEO from '@components/seo';
import Header from '@components/blocks/header';
import SlidesSwitcher from '@components/blocks/slides-switcher';
import Intro from '@components/blocks/intro';
import Portfolio from '@components/blocks/portfolio';
import styled, {createGlobalStyle, css} from 'styled-components';
import {getPartitionPercent} from '@utils/math-addition';
import Contacts from '@components/blocks/contacts';
import Rate from '@components/blocks/rate';
import {useScrollHandler} from '@hooks/scroll-handler';
import useResponsive from '@hooks/responsive';
import {IndexPageContext} from '@components/context/index-provider';
import { useEffect } from 'react';
import { APP_VERSION } from 'gatsby-env-variables';

const {useState, useCallback} = React;

const ENABLE_ANIMATION_DEFAULT = true;
const PAGE_COUNT = 3;
const CHANGE_SLIDE_DURATION = 750;
const CHANGE_SLIDE_DURATION_CSS = `${CHANGE_SLIDE_DURATION}ms`;
const CHANGE_SLIDE_TIMING_FUNCTION = 'ease-out';
const PORTFOLIO_SCROLL_DURATION = 200;

// 0 [0, 0, 0] intro
// 1 [1, 0, 0] portfolio
// 2 [1, 1, 0] rate
// 3 [1, 1, 1] contacts

console.log(APP_VERSION);

const GlobalStyle = createGlobalStyle<{enableAnimation: boolean}>`
  body {
    background-color: ${({enableAnimation}) => enableAnimation && 'black'};
  }
`;

const WrapScroll = styled.div``;

const SHeader = styled.header`
  position: relative;
  z-index: 100;
  
`;

const Main = styled.main`
  overflow: hidden;
`;

interface ISectionIntro {
  scrollPercent: number;
  enableAnimation: boolean;
}

const SectionIntro = styled.section.attrs<ISectionIntro>(({
  scrollPercent,
}) => ({
  style: {
    top: `calc(-${scrollPercent * 100}vh ${scrollPercent > 0.95 ? '- 30px' : ''})`,
  },
}))<ISectionIntro>`
  z-index: 60;
  transition: top ${CHANGE_SLIDE_TIMING_FUNCTION} ${CHANGE_SLIDE_DURATION_CSS};
  left: 0;
  right: 0;
  top: 0;
  
  @media (min-width: 1024px) {
    position: ${({enableAnimation}) => enableAnimation && 'fixed'};
    height: 100vh;
  }
`;

interface ISectionPortfolio {
  scrollPercent: number;
  scrollPercentPreventPage: number;
  isDesktop: boolean;
  enableAnimation: boolean;
}

const sectionPortfolioHeight = css`
  position: fixed;
  height: 100vh;
  min-height: 100vh;
  max-height: 100vh;
`;

const SectionPortfolio = styled.section.attrs<ISectionPortfolio>(({
  scrollPercent,
  scrollPercentPreventPage,
  isDesktop,
  enableAnimation,
}) => ({
  style: {
    top: `calc(-${scrollPercent * 100.2}vh)`,
    opacity: isDesktop && enableAnimation ? Math.max(scrollPercentPreventPage, 0) : 1,
  },
}))<ISectionPortfolio>`
  z-index: 50;
  transition:
    top ${CHANGE_SLIDE_TIMING_FUNCTION} ${CHANGE_SLIDE_DURATION_CSS},
    opacity ${CHANGE_SLIDE_TIMING_FUNCTION} ${CHANGE_SLIDE_DURATION_CSS};
    margin-top: ${({isDesktop}) => !isDesktop ? '-40px' : 'none'};

  
  @media (min-width: 1024px) {
    ${({enableAnimation}) => enableAnimation && sectionPortfolioHeight}
  }
`;

interface ISectionRate {
  isDesktop: boolean;
  scrollPercent: number;
  scrollPercentPreventPage: number;
  scrollPercentPrePreventPage: number;
  enableAnimation: boolean;
}

const getTopSectionRate = (scrollPercent) =>
  `calc(-${scrollPercent * 100}vh ${scrollPercent > 0.95 ? '- 30px' : ''})`;

const getVisibilitySectionRate = (scrollPercentPrePreventPage) =>
  scrollPercentPrePreventPage > 0.5 ? 'visible' : 'hidden';

const SectionRate = styled.section.attrs<ISectionRate>(({
  isDesktop,
  scrollPercent,
  scrollPercentPreventPage,
  scrollPercentPrePreventPage,
  enableAnimation,
}) => ({
  style: enableAnimation ? {
    top: isDesktop ? getTopSectionRate(scrollPercent) : '',
    visibility: isDesktop ? getVisibilitySectionRate(scrollPercentPrePreventPage) : '',
    opacity: isDesktop ? Math.max(scrollPercentPreventPage, 0) : 1,

  } : {},
}))<ISectionRate>`
  z-index: 15;
  transition:
    top ${CHANGE_SLIDE_TIMING_FUNCTION} ${CHANGE_SLIDE_DURATION_CSS},
    opacity ${CHANGE_SLIDE_TIMING_FUNCTION} ${CHANGE_SLIDE_DURATION_CSS} ;
  left: 0;
  right: 0;
  top: 0;
  background-color: white;
  margin-top: ${({isDesktop}) => !isDesktop ? '-50px' : 'none'};
  @media (min-width: 1024px) {
    position: ${({enableAnimation}) => enableAnimation ? 'fixed' : 'relative'};
    height: ${({enableAnimation}) => enableAnimation && '100vh'};
    
  }
`;

interface ISectionContacts {
  isDesktop: boolean;
  scrollPercentPreventPage: number;
  scrollPercentPrePreventPage: number;
  enableAnimation: boolean;
}

const getVisibilitySectionContacts = (scrollPercentPrePreventPage) =>
  scrollPercentPrePreventPage >= 0.9 ? 'visible' : 'hidden';

const SectionContacts = styled.section.attrs<ISectionContacts>(({
  isDesktop,
  scrollPercentPreventPage,
  scrollPercentPrePreventPage,
  enableAnimation,
}) => ({
  style: enableAnimation ? {
    visibility: isDesktop ? getVisibilitySectionContacts(scrollPercentPrePreventPage) : '',
    opacity: isDesktop ? Math.max(scrollPercentPreventPage, 0) : 1,

  } : {},
}))<ISectionContacts>`
  transition: opacity ${CHANGE_SLIDE_TIMING_FUNCTION} ${CHANGE_SLIDE_DURATION_CSS} ;
  z-index: 5;
  left: 0;
  right: 0;
  top: 0;
  background-color: white;
  
  @media (min-width: 1024px) {
    position: ${({enableAnimation}) => enableAnimation && 'fixed'};
    height: 100vh;
  }
`;

const IndexPage = () => {
  const isDesktop = useResponsive('(min-width: 1024px)', true);
  // const isEnougthHeight = useResponsive('(max-height: 770px)');
  const [pageScrollPercent, setPageScrollPercent] = useState(0);
  const [textAnimationProgress, setTextAnimationProgress] = useState(0);
  const [portfolioScrollDuration, setPortfolioScrollDuration] = useState(PORTFOLIO_SCROLL_DURATION);
  const [visibleIntro, setVisibleIntro] = useState(true);
  const animationProgressRef = React.useRef({changeSlide: {disable: false, timerId: null}});
  const isDesktopRef = React.useRef(isDesktop);
  isDesktopRef.current = isDesktop;
  const pageScrollPercentRef = React.useRef(pageScrollPercent);
  pageScrollPercentRef.current = pageScrollPercent;

  const refPortfolio = React.useRef(null);
  const refRate = React.useRef(null);

  const [enableAnimation, setEnableAnimation] = useState(ENABLE_ANIMATION_DEFAULT);
  const isEnougthHeight = false; // <--- need to delete

  useEffect(() => {
    setEnableAnimation(!isEnougthHeight);
  }, [isEnougthHeight, setEnableAnimation]);

  const setPageScrollPercentByTargets = useCallback((targets: number | Array<number>, percent) => {
    const setPage = (slide) => {
      const result = (slide + percent) / PAGE_COUNT;
      if (slide < 1) {
        setVisibleIntro(true);
      }
      setPageScrollPercent(result);
    };

    if (typeof targets === 'number') {
      setPage(targets);
    } else {
      setPortfolioScrollDuration(CHANGE_SLIDE_DURATION);
      animationProgressRef.current.changeSlide.disable = true;

      const finish = () => {
        animationProgressRef.current.changeSlide.disable = false;
        setPortfolioScrollDuration(PORTFOLIO_SCROLL_DURATION);
      };

      for (let step = 0; step < targets.length; step += 1) {
        const isLast = (targets.length - 1) === step;
        if (isLast) {
          setTimeout(finish, (CHANGE_SLIDE_DURATION * (step + 1)));
        }
        setTimeout(setPage, (CHANGE_SLIDE_DURATION * step), targets[step]);
      }
    }
  }, []);

  const setPageScrollPercentByIndexThottled = useCallback((i, percent = 0, timeout = CHANGE_SLIDE_DURATION) => {
    if (!animationProgressRef.current.changeSlide.disable) {
      setPageScrollPercentByTargets(i, percent);
      animationProgressRef.current.changeSlide.disable = true;
      clearInterval(animationProgressRef.current.changeSlide.timerId);
      setTimeout(() => animationProgressRef.current.changeSlide.disable = false, timeout);
    }
  }, []);

  const scrollPercentArray = (new Array(PAGE_COUNT)).fill('').map((el, i) =>
    getPartitionPercent(PAGE_COUNT, 1, i, pageScrollPercent));

  const scrollPercentArrayRef = React.useRef(scrollPercentArray);
  scrollPercentArrayRef.current = scrollPercentArray;
  const refIntro = React.useRef<HTMLElement>();

  const getScrollPercent = (index, allDevice?) => isDesktop || allDevice
    ? scrollPercentArrayRef.current[index] : null;

  const setPageScrollPercentByIndex = useCallback((positionIndex, percent) => {
    if (getScrollPercent(2) === 1) { // slide 3
      if (positionIndex === 0) {
        setPageScrollPercentByTargets([2, 1, 0], percent);
      } else if (positionIndex === 1) {
        setPageScrollPercentByTargets([2, 1], percent);
      } else if (positionIndex === 2) {
        setPageScrollPercentByTargets([2], percent);
      }
    } else if (getScrollPercent(1) === 1) { // slide 2
      if (positionIndex === 0) {
        setPageScrollPercentByTargets([1, 0], percent);
      } else if (positionIndex === 1) {
        setPageScrollPercentByTargets([1], percent);
      } else if (positionIndex === 3) {
        setPageScrollPercentByTargets([3], percent);
      }
    } else if (getScrollPercent(0) === 1) { // slide 1
      if (positionIndex === 0) {
        setPageScrollPercentByTargets([0], percent);
      } else if (positionIndex === 2) {
        setPageScrollPercentByTargets([2], percent);
      } else if (positionIndex === 3) {
        setPageScrollPercentByTargets([2, 3], percent);
      }
    } else { // slide 0
      if (positionIndex === 3) {
        setPageScrollPercentByTargets([1, 2, 3], percent);
      } else if (positionIndex === 2) {
        setPageScrollPercentByTargets([1, 2], percent);
      } else if (positionIndex === 1) {
        setPageScrollPercentByTargets([1], percent);
      }
    }
  }, []);

  const mouseScrollHandlerDown = (e: Event) => {
    if (!isDesktopRef.current || !enableAnimation) {
      return;
    }
    e.preventDefault();
    if (getScrollPercent(1) === 1) {
      setPageScrollPercentByIndexThottled(3);
    } else if (getScrollPercent(0) === 1) {
      setPageScrollPercentByIndexThottled(2);
    } else {
      setPageScrollPercentByIndexThottled(1);
    }

    setTextAnimationProgress(getScrollPercent(0));

    const {current: introElement} = refIntro;
    if (introElement) {
      setVisibleIntro(introElement.getBoundingClientRect().bottom >= 0);
    }
  };

  // console.log(scrollPercentArrayRef.current);

  const mouseScrollHandlerUp = (e: Event) => {
    if (!isDesktopRef.current || !enableAnimation) {
      return;
    }
    e.preventDefault();
    if (getScrollPercent(2) === 1) {
      setPageScrollPercentByIndexThottled(2);
    } else if (getScrollPercent(1) === 1) {
      setPageScrollPercentByIndexThottled(1);
    } else {
      setPageScrollPercentByIndexThottled(0);
    }

    setVisibleIntro(getScrollPercent(0) < 1);
    setTextAnimationProgress(0);
  };

  useScrollHandler({
    downHandler: mouseScrollHandlerDown,
    upHandler: mouseScrollHandlerUp,
    deps: [enableAnimation],
  });

  return (
    <IndexPageContext.Provider value={{setPageScrollPercentByIndex, enableAnimation}}>
      <MainLayout>
        <SEO title="Home" />
        <GlobalStyle enableAnimation={enableAnimation} />

        <WrapScroll>
          <Main>
            <SlidesSwitcher
              isAnimationActive={animationProgressRef.current.changeSlide.disable}
              getScrollPercent={getScrollPercent}
              refIntro={refIntro}
              refPortfolio={refPortfolio}
              refRate={refRate}
            />
            <SectionIntro enableAnimation={enableAnimation} ref={refIntro} scrollPercent={getScrollPercent(0)}>
              <SHeader>
                <Header
                  refIntro={refIntro}
                  refPortfolio={refPortfolio}
                  refRate={refRate}
                />
              </SHeader>

              {((isDesktop && visibleIntro) || !isDesktop || !enableAnimation) && (
                <Intro
                  isDesktop={isDesktop}
                  isEnougthHeight={isEnougthHeight}
                  enableAnimation={enableAnimation}
                  setEnableAnimation={setEnableAnimation}
                  scrollPercent={getScrollPercent(0)}
                  textAnimationProgress={textAnimationProgress}
                />
              )}
            </SectionIntro>

            <SectionPortfolio
              scrollPercent={getScrollPercent(1)}
              scrollPercentPreventPage={getScrollPercent(0)}
              isDesktop={isDesktop}
              enableAnimation={enableAnimation}
              ref={refPortfolio}
            >
              <Portfolio
                enableAnimation={enableAnimation}
                isDesktop={isDesktop}
                downHandler={mouseScrollHandlerDown}
                upHandler={mouseScrollHandlerUp}
              />
            </SectionPortfolio>

            <SectionRate
              isDesktop={isDesktop}
              scrollPercent={getScrollPercent(2)}
              scrollPercentPrePreventPage={getScrollPercent(0)}
              scrollPercentPreventPage={getScrollPercent(1)}
              enableAnimation={enableAnimation}
              ref={refRate}
            >
              <Rate enableAnimation={enableAnimation} />
            </SectionRate>

            <SectionContacts
              isDesktop={isDesktop}
              scrollPercentPrePreventPage={getScrollPercent(1)}
              scrollPercentPreventPage={getScrollPercent(2)}
              enableAnimation={enableAnimation}
            >
              <Contacts enableAnimation={enableAnimation} stopAnimation={getScrollPercent(1, true) < 0.9} isDesctop={isDesktop} />
            </SectionContacts>
          </Main>
        </WrapScroll>
      </MainLayout>
    </IndexPageContext.Provider>
  );
};

export default IndexPage;
