import Other from './other';
import Blog from './blog';
import OnlineStorefront from './online-storefront';
import OnlineStore from './online-store';
import CorporateSite from './corporate-site';
import LandingPage from './landing-page';
import BussinesCard from './bussines-card';

export {
  Other,
  Blog,
  OnlineStorefront,
  OnlineStore,
  CorporateSite,
  LandingPage,
  BussinesCard,
};
