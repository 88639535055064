import * as React from "react";

function LandingPage(props) {
  return (
    <svg viewBox="0 0 76 76" fill="none" {...props}>
      <path
        d="M70.063 3.563H5.936a3.567 3.567 0 00-3.562 3.562v61.75a3.567 3.567 0 003.563 3.563h64.124a3.567 3.567 0 003.563-3.563V7.125a3.567 3.567 0 00-3.563-3.563zM5.936 5.937h64.126a1.189 1.189 0 011.187 1.188v5.938H4.75V7.125a1.189 1.189 0 011.188-1.188zm64.126 64.126H5.936a1.19 1.19 0 01-1.187-1.188V15.437h66.5v53.438a1.19 1.19 0 01-1.188 1.188z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M17.813 10.688a1.188 1.188 0 100-2.376 1.188 1.188 0 000 2.376zM13.063 10.688a1.188 1.188 0 100-2.376 1.188 1.188 0 000 2.376zM8.313 10.688a1.188 1.188 0 100-2.376 1.188 1.188 0 000 2.376zM65.313 20.188H10.687A1.188 1.188 0 009.5 21.375v19a1.188 1.188 0 001.188 1.188h54.624a1.188 1.188 0 001.188-1.188v-19a1.188 1.188 0 00-1.188-1.188zM45.844 37.772l2.843-2.843 4.258 4.258h-6.04l-1.061-1.415zm-1.907 1.416h-9.5l4.75-6.334 4.75 6.334zm-12.47 0h-9L28.5 30.57l4.553 6.504-1.584 2.112zm32.657 0h-7.82l-6.778-6.778a1.186 1.186 0 00-1.68 0l-3.442 3.443-4.267-5.69a1.186 1.186 0 00-1.9 0l-3.685 4.913-5.08-7.257a1.187 1.187 0 00-1.946 0L19.57 39.188h-7.694V22.561h52.25v16.625z"
        fill="#232323"
        fillOpacity={0.86}
      />
      <path
        d="M45.125 29.688a1.188 1.188 0 100-2.376 1.188 1.188 0 000 2.375zM42.75 46.313a1.188 1.188 0 100-2.376 1.188 1.188 0 000 2.376zM38 46.313a1.188 1.188 0 100-2.376 1.188 1.188 0 000 2.376zM33.25 46.313a1.188 1.188 0 100-2.376 1.188 1.188 0 000 2.376zM24.938 48.688h-14.25A1.188 1.188 0 009.5 49.874v9.5a1.188 1.188 0 001.188 1.188h14.25a1.188 1.188 0 001.187-1.188v-9.5a1.188 1.188 0 00-1.188-1.188zm-1.188 2.374v6.792l-5.195-4.156a1.188 1.188 0 00-1.485 0l-5.195 4.156v-6.791H23.75zm-3.385 7.126H15.26l2.553-2.042 2.552 2.041zM45.125 48.688h-14.25a1.188 1.188 0 00-1.188 1.187v9.5a1.188 1.188 0 001.188 1.188h14.25a1.188 1.188 0 001.188-1.188v-9.5a1.188 1.188 0 00-1.188-1.188zm-1.188 2.374v6.792l-5.195-4.156a1.188 1.188 0 00-1.484 0l-5.196 4.156v-6.791h11.876zm-3.385 7.126h-5.104L38 56.145l2.552 2.041zM65.313 48.688h-14.25a1.188 1.188 0 00-1.188 1.187v9.5a1.188 1.188 0 001.188 1.188h14.25a1.188 1.188 0 001.187-1.188v-9.5a1.188 1.188 0 00-1.188-1.188zm-1.188 2.374v6.792l-5.195-4.156a1.188 1.188 0 00-1.485 0l-5.195 4.156v-6.791h11.875zm-3.385 7.126h-5.105l2.553-2.042 2.552 2.041zM22.563 62.938h-9.5a1.188 1.188 0 000 2.374h9.5a1.188 1.188 0 000-2.374zM42.75 62.938h-9.5a1.188 1.188 0 000 2.374h9.5a1.188 1.188 0 000-2.374zM62.938 62.938h-9.5a1.188 1.188 0 000 2.374h9.5a1.188 1.188 0 000-2.374z"
        fill="#232323"
        fillOpacity={0.86}
      />
    </svg>
  );
}

const MemoLandingPage = React.memo(LandingPage);
export default MemoLandingPage;
