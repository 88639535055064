import * as React from 'react';

export type OrderType = 'lite' | 'standard' | 'premium';

export const mapOrderTypeTitles: { [key in OrderType]: string } = {
  lite: 'Лайт',
  standard: 'Стандарт',
  premium: 'Премиум',
};

export const mapOrderRates = {
  businessCard: {
    lite: {
      cost: '6 000',
      properties: [
        '1 блок с информацией',
        'Дизайн категории lite',
      ],
    },
    standard: {
      cost: '12 000',
      properties: [
        '1 блок с информацией',
        'Дизайн категории standard',
      ],
    },
    premium: {
      cost: '18 000',
      properties: [
        '1 блок с информацией',
        <>Дизайн категории <strong>premium</strong></>,
        'Анимации',
      ],
    },
  },

  landing: {
    lite: {
      cost: '15 000',
      properties: [
        '4 блока с информацией',
        'Дизайн категории lite',
        'Базовая SEO оптимизация',
        'Базовая поддержка браузеров',
        'Минимальный адаптив под мобильные устройства',
      ],
    },
    standard: {
      cost: '35 000',
      properties: [
        '4 блока с информацией',
        'Дизайн категории standard',
        'SEO оптимизиация',
        'Простые анимации',
        'Стандратная поддержка браузеров',
        'Адаптив под мобильные устройства',
      ],
    },
    premium: {
      cost: '80 000',
      properties: [
        '4 и более блоков с информацией',
        <>Дизайн категории <strong>premium</strong></>,
        'SEO оптимизиация',
        'Анимации',
        'Расширенная поддержка браузеров',
        'Адаптив под мобильные устройства',
      ],
    },
  },

  corporateSite: {
    lite: {
      cost: '20 000',
      properties: [
        '1 страница с информацией',
        'Дизайн категории lite',
        'Базовая SEO оптимизация',
        'Базовая поддержка браузеров',
        'Минимальный адаптив под мобильные устройства',
      ],
    },
    standard: {
      cost: '50 000',
      properties: [
        '3 и более страницы с информацией',
        'Дизайн категории standard',
        'SEO оптимизация',
        'Поддержка браузеров',
        'Адаптив под мобильные устройства',
      ],
    },
    premium: {
      cost: '100 000',
      properties: [
        '5 и более страниц с информацией',
        <>Дизайн категории <strong>premium</strong></>,
        'SEO оптимизация',
        'Поддержка браузеров',
        'Адаптив под мобильные устройства',
        'Интеграция с CMS',
        'Admin-панель для управления содержимым',
      ],
    },
  },

  cabinet: {
    lite: {
      cost: '70 000',
      properties: [
        'Авторизация/регистрация',
        'Просмотр личного профиля',
        'Базовый минимальный функционал по ТЗ',
        'Дизайн категории lite',
      ],
    },
    standard: {
      cost: '150 000',
      properties: [
        'Авторизация/регистрация',
        'Аторизация через социцальные сети',
        'Просмотр личного профиля',
        'Функционал по ТЗ',
        'Дизайн категории standard',
      ],
    },
    premium: {
      cost: '250 000',
      properties: [
        'Авторизация/регистрация',
        'Аторизация через социцальные сети',
        'Просмотр личного профиля',
        'Расширенные функционал по ТЗ',
        <>Дизайн категории <strong>premium</strong></>,
      ],
    },
  },

  onlineService: {
    lite: {
      cost: '100 000',
      properties: [
        'Личный кабинет категории lite',
        'Дизайн категории lite',
        'Функционал по ТЗ',
      ],
    },
    standard: {
      cost: '250 000',
      properties: [
        'Личный кабинет категории standard',
        'Дизайн категории standard',
        'Функционал по ТЗ повышенной сложности',
      ],
    },
    premium: {
      cost: '500 000',
      properties: [
        'Личный кабинет категории premium',
        <>Дизайн категории <strong>premium</strong></>,
        'Функционал по ТЗ любой сложности',
      ],
    },
  },

  onlineStore: {
    lite: {
      cost: '100 000',
      properties: [
        'Дизайн категории lite',
        'Каталог товаров',
        'Корзина товаров',
        'Форма заказа без online-оплаты',
        'CMS система',
        'Admin-панель',
      ],
    },
    standard: {
      cost: '150 000',
      properties: [
        'Дизайн категории standard',
        'Каталог товаров',
        'Корзина товаров',
        'Форма заказа с online-оплатой',
        'CMS система',
        'CRM система',
        'Admin-панель',
      ],
    },
    premium: {
      cost: '250 000',
      properties: [
        <>Дизайн категории <strong>premium</strong></>,
        'Каталог товаров',
        'Корзина товаров',
        'Форма заказа с online-оплатой',
        'CMS система',
        'CRM система',
        'Admin-панель',
      ],
    },
  },

  mobileApplication: {
    lite: {
      cost: '100 000',
      properties: [
        'Дизайн категории lite',
        'Android и iOS',
        'Базовый функционал по ТЗ',
      ],
    },
    standard: {
      cost: '200 000',
      properties: [
        'Дизайн категории standard',
        'Android и iOS',
        'Функционал повышенной сложности по ТЗ',
      ],
    },
    premium: {
      cost: '300 000',
      properties: [
        <>Дизайн категории <strong>premium</strong></>,
        'Android и iOS',
        'Функционал любой сложности по ТЗ',
      ],
    },
  },
};

export type Order = keyof typeof mapOrderRates;

export const mapOrderSectionTitles: { [key in Order]: string } = {
  cabinet: 'Личный кабинет',
  corporateSite: 'Корпоративный сайт',
  landing: 'Лендинг',
  businessCard: 'Сайт-визитка',
  mobileApplication: 'Мобильное приложение',
  onlineService: 'Онлайн сервис',
  onlineStore: 'Онлайн магазин',
};
